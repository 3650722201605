import { z } from "zod";
import { OccupationListReadonly, OccupationsType } from "./Occupations";
import { MasterTagId } from "./Tag";

export const userSearchFormSchema = z
  .object({
    minAge: z.number().or(z.enum(["undefined"])),
    maxAge: z.number().or(z.enum(["undefined"])),
    minIncome: z.number().or(z.enum(["undefined"])),
    maxIncome: z.number().or(z.enum(["undefined"])),
    occupation: z.enum(OccupationListReadonly).or(z.enum(["undefined"])),
    lengthOfService: z.number().or(z.enum(["undefined"])),
    interestList: z
      .union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7),
        z.literal(8),
        z.literal(9),
        z.literal(10),
        z.literal(11),
        z.literal(12),
      ])
      .array(),
  })
  .refine(
    (args) => {
      const { minAge, maxAge } = args;
      if (minAge === "undefined" || maxAge === "undefined") return true;
      return minAge <= maxAge;
    },
    {
      message: "範囲が逆転しています",
      path: ["minAge"],
    }
  )
  .refine(
    (args) => {
      const { minAge, maxAge } = args;
      if (minAge === "undefined" || maxAge === "undefined") return true;
      return minAge <= maxAge;
    },
    {
      message: "範囲が逆転しています",
      path: ["maxAge"],
    }
  )
  .refine(
    (args) => {
      const { minIncome, maxIncome } = args;
      if (minIncome === "undefined" || maxIncome === "undefined") return true;
      return minIncome <= maxIncome;
    },
    {
      message: "範囲が逆転しています",
      path: ["minIncome"],
    }
  )
  .refine(
    (args) => {
      const { minIncome, maxIncome } = args;
      if (minIncome === "undefined" || maxIncome === "undefined") return true;
      return minIncome <= maxIncome;
    },
    {
      message: "範囲が逆転しています",
      path: ["maxIncome"],
    }
  );
export type UserSearchFormSchema = z.infer<typeof userSearchFormSchema>;

type RangeOption = Partial<{
  min: number | "undefined";
  max: number | "undefined";
}>;

export type UserSearchRequestType = Partial<{
  age: RangeOption;
  annual_income: RangeOption;
  job_category: OccupationsType | "undefined";
  job_continuous_year: number | "undefined";
  interest_tag_ids: number[];
  is_favorite: boolean;
}>;

export type SearchFormType = {
  minAge: number | "undefined";
  maxAge: number | "undefined";
  minIncome: number | "undefined";
  maxIncome: number | "undefined";
  occupation: OccupationsType | "undefined";
  lengthOfService: number | "undefined";
  interestList: MasterTagId[];
};

export const defaultUserSearchFormType: SearchFormType = {
  minAge: "undefined",
  maxAge: "undefined",
  minIncome: "undefined",
  maxIncome:"undefined",
  occupation: "undefined",
  lengthOfService: 0,
  interestList: [],
}
