/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The LeaseContract model module.
 * @module model/LeaseContract
 * @version 1.0.0
 */
class LeaseContract {
    /**
     * Constructs a new <code>LeaseContract</code>.
     * @alias module:model/LeaseContract
     * @param monthlyFee {Number} 
     * @param monthlyManagementFee {Number} 
     * @param startsAt {Date} 
     * @param endsAt {Date} 
     */
    constructor(monthlyFee, monthlyManagementFee, startsAt, endsAt) { 
        
        LeaseContract.initialize(this, monthlyFee, monthlyManagementFee, startsAt, endsAt);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, monthlyFee, monthlyManagementFee, startsAt, endsAt) { 
        obj['monthly_fee'] = monthlyFee;
        obj['monthly_management_fee'] = monthlyManagementFee;
        obj['starts_at'] = startsAt;
        obj['ends_at'] = endsAt;
    }

    /**
     * Constructs a <code>LeaseContract</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LeaseContract} obj Optional instance to populate.
     * @return {module:model/LeaseContract} The populated <code>LeaseContract</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LeaseContract();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('property_id')) {
                obj['property_id'] = ApiClient.convertToType(data['property_id'], 'Number');
            }
            if (data.hasOwnProperty('monthly_fee')) {
                obj['monthly_fee'] = ApiClient.convertToType(data['monthly_fee'], 'Number');
            }
            if (data.hasOwnProperty('monthly_management_fee')) {
                obj['monthly_management_fee'] = ApiClient.convertToType(data['monthly_management_fee'], 'Number');
            }
            if (data.hasOwnProperty('starts_at')) {
                obj['starts_at'] = ApiClient.convertToType(data['starts_at'], 'Date');
            }
            if (data.hasOwnProperty('ends_at')) {
                obj['ends_at'] = ApiClient.convertToType(data['ends_at'], 'Date');
            }
            if (data.hasOwnProperty('outsourcing_fee_rate_of_new_lease_contract')) {
                obj['outsourcing_fee_rate_of_new_lease_contract'] = ApiClient.convertToType(data['outsourcing_fee_rate_of_new_lease_contract'], 'Number');
            }
            if (data.hasOwnProperty('outsourcing_fee_rate_of_updating_lease_contract')) {
                obj['outsourcing_fee_rate_of_updating_lease_contract'] = ApiClient.convertToType(data['outsourcing_fee_rate_of_updating_lease_contract'], 'Number');
            }
            if (data.hasOwnProperty('key_money')) {
                obj['key_money'] = ApiClient.convertToType(data['key_money'], 'Number');
            }
            if (data.hasOwnProperty('security_deposit')) {
                obj['security_deposit'] = ApiClient.convertToType(data['security_deposit'], 'Number');
            }
            if (data.hasOwnProperty('type_of_the_income_of_the_beginning_of_contract')) {
                obj['type_of_the_income_of_the_beginning_of_contract'] = ApiClient.convertToType(data['type_of_the_income_of_the_beginning_of_contract'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('discarded_at')) {
                obj['discarded_at'] = ApiClient.convertToType(data['discarded_at'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>LeaseContract</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>LeaseContract</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of LeaseContract.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['type_of_the_income_of_the_beginning_of_contract'] && !(typeof data['type_of_the_income_of_the_beginning_of_contract'] === 'string' || data['type_of_the_income_of_the_beginning_of_contract'] instanceof String)) {
            throw new Error("Expected the field `type_of_the_income_of_the_beginning_of_contract` to be a primitive type in the JSON string but got " + data['type_of_the_income_of_the_beginning_of_contract']);
        }

        return true;
    }


}

LeaseContract.RequiredProperties = ["monthly_fee", "monthly_management_fee", "starts_at", "ends_at"];

/**
 * @member {Number} id
 */
LeaseContract.prototype['id'] = undefined;

/**
 * @member {Number} property_id
 */
LeaseContract.prototype['property_id'] = undefined;

/**
 * @member {Number} monthly_fee
 */
LeaseContract.prototype['monthly_fee'] = undefined;

/**
 * @member {Number} monthly_management_fee
 */
LeaseContract.prototype['monthly_management_fee'] = undefined;

/**
 * @member {Date} starts_at
 */
LeaseContract.prototype['starts_at'] = undefined;

/**
 * @member {Date} ends_at
 */
LeaseContract.prototype['ends_at'] = undefined;

/**
 * 新規賃貸契約手数料 (賃料のN倍)
 * @member {Number} outsourcing_fee_rate_of_new_lease_contract
 */
LeaseContract.prototype['outsourcing_fee_rate_of_new_lease_contract'] = undefined;

/**
 * 賃貸契約更新手数料（賃料のN倍)
 * @member {Number} outsourcing_fee_rate_of_updating_lease_contract
 */
LeaseContract.prototype['outsourcing_fee_rate_of_updating_lease_contract'] = undefined;

/**
 * @member {Number} key_money
 */
LeaseContract.prototype['key_money'] = undefined;

/**
 * @member {Number} security_deposit
 */
LeaseContract.prototype['security_deposit'] = undefined;

/**
 * @member {module:model/LeaseContract.TypeOfTheIncomeOfTheBeginningOfContractEnum} type_of_the_income_of_the_beginning_of_contract
 */
LeaseContract.prototype['type_of_the_income_of_the_beginning_of_contract'] = undefined;

/**
 * @member {Date} created_at
 */
LeaseContract.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
LeaseContract.prototype['updated_at'] = undefined;

/**
 * @member {Date} discarded_at
 */
LeaseContract.prototype['discarded_at'] = undefined;





/**
 * Allowed values for the <code>type_of_the_income_of_the_beginning_of_contract</code> property.
 * @enum {String}
 * @readonly
 */
LeaseContract['TypeOfTheIncomeOfTheBeginningOfContractEnum'] = {

    /**
     * value: "KeyMoney"
     * @const
     */
    "KeyMoney": "KeyMoney",

    /**
     * value: "SecurityDeposit"
     * @const
     */
    "SecurityDeposit": "SecurityDeposit"
};



export default LeaseContract;

