/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Agent from './Agent';
import ContractApplication from './ContractApplication';
import ContractApplicationAttachmentFile from './ContractApplicationAttachmentFile';
import User from './User';

/**
 * The ContractApplicationPatchRequest model module.
 * @module model/ContractApplicationPatchRequest
 * @version 1.0.0
 */
class ContractApplicationPatchRequest {
    /**
     * Constructs a new <code>ContractApplicationPatchRequest</code>.
     * @alias module:model/ContractApplicationPatchRequest
     * @implements module:model/ContractApplication
     * @param applicationDate {Date} 
     * @param contractPrice {Number} 
     * @param contractPropertyName {String} 
     * @param contractDate {Date} 
     */
    constructor(applicationDate, contractPrice, contractPropertyName, contractDate) { 
        ContractApplication.initialize(this);
        ContractApplicationPatchRequest.initialize(this, applicationDate, contractPrice, contractPropertyName, contractDate);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, applicationDate, contractPrice, contractPropertyName, contractDate) { 
        obj['application_date'] = applicationDate;
        obj['contract_price'] = contractPrice;
        obj['contract_property_name'] = contractPropertyName;
        obj['contract_date'] = contractDate;
    }

    /**
     * Constructs a <code>ContractApplicationPatchRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ContractApplicationPatchRequest} obj Optional instance to populate.
     * @return {module:model/ContractApplicationPatchRequest} The populated <code>ContractApplicationPatchRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ContractApplicationPatchRequest();
            ContractApplication.constructFromObject(data, obj);

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('proposal_matching_id')) {
                obj['proposal_matching_id'] = ApiClient.convertToType(data['proposal_matching_id'], 'Number');
            }
            if (data.hasOwnProperty('agent_id')) {
                obj['agent_id'] = ApiClient.convertToType(data['agent_id'], 'Number');
            }
            if (data.hasOwnProperty('user_id')) {
                obj['user_id'] = ApiClient.convertToType(data['user_id'], 'Number');
            }
            if (data.hasOwnProperty('application_date')) {
                obj['application_date'] = ApiClient.convertToType(data['application_date'], 'Date');
            }
            if (data.hasOwnProperty('contract_price')) {
                obj['contract_price'] = ApiClient.convertToType(data['contract_price'], 'Number');
            }
            if (data.hasOwnProperty('contract_property_name')) {
                obj['contract_property_name'] = ApiClient.convertToType(data['contract_property_name'], 'String');
            }
            if (data.hasOwnProperty('contract_date')) {
                obj['contract_date'] = ApiClient.convertToType(data['contract_date'], 'Date');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('agent')) {
                obj['agent'] = Agent.constructFromObject(data['agent']);
            }
            if (data.hasOwnProperty('user')) {
                obj['user'] = User.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('contract_application_attachment_files')) {
                obj['contract_application_attachment_files'] = ApiClient.convertToType(data['contract_application_attachment_files'], [ContractApplicationAttachmentFile]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ContractApplicationPatchRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ContractApplicationPatchRequest</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ContractApplicationPatchRequest.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['contract_property_name'] && !(typeof data['contract_property_name'] === 'string' || data['contract_property_name'] instanceof String)) {
            throw new Error("Expected the field `contract_property_name` to be a primitive type in the JSON string but got " + data['contract_property_name']);
        }
        // validate the optional field `agent`
        if (data['agent']) { // data not null
          Agent.validateJSON(data['agent']);
        }
        // validate the optional field `user`
        if (data['user']) { // data not null
          User.validateJSON(data['user']);
        }
        if (data['contract_application_attachment_files']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['contract_application_attachment_files'])) {
                throw new Error("Expected the field `contract_application_attachment_files` to be an array in the JSON data but got " + data['contract_application_attachment_files']);
            }
            // validate the optional field `contract_application_attachment_files` (array)
            for (const item of data['contract_application_attachment_files']) {
                ContractApplicationAttachmentFile.validateJSON(item);
            };
        }

        return true;
    }


}

ContractApplicationPatchRequest.RequiredProperties = ["application_date", "contract_price", "contract_property_name", "contract_date"];

/**
 * @member {Number} id
 */
ContractApplicationPatchRequest.prototype['id'] = undefined;

/**
 * @member {Number} proposal_matching_id
 */
ContractApplicationPatchRequest.prototype['proposal_matching_id'] = undefined;

/**
 * @member {Number} agent_id
 */
ContractApplicationPatchRequest.prototype['agent_id'] = undefined;

/**
 * @member {Number} user_id
 */
ContractApplicationPatchRequest.prototype['user_id'] = undefined;

/**
 * @member {Date} application_date
 */
ContractApplicationPatchRequest.prototype['application_date'] = undefined;

/**
 * @member {Number} contract_price
 */
ContractApplicationPatchRequest.prototype['contract_price'] = undefined;

/**
 * @member {String} contract_property_name
 */
ContractApplicationPatchRequest.prototype['contract_property_name'] = undefined;

/**
 * @member {Date} contract_date
 */
ContractApplicationPatchRequest.prototype['contract_date'] = undefined;

/**
 * @member {Date} created_at
 */
ContractApplicationPatchRequest.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ContractApplicationPatchRequest.prototype['updated_at'] = undefined;

/**
 * @member {module:model/Agent} agent
 */
ContractApplicationPatchRequest.prototype['agent'] = undefined;

/**
 * @member {module:model/User} user
 */
ContractApplicationPatchRequest.prototype['user'] = undefined;

/**
 * @member {Array.<module:model/ContractApplicationAttachmentFile>} contract_application_attachment_files
 */
ContractApplicationPatchRequest.prototype['contract_application_attachment_files'] = undefined;


// Implement ContractApplication interface:
/**
 * @member {Number} id
 */
ContractApplication.prototype['id'] = undefined;
/**
 * @member {Number} proposal_matching_id
 */
ContractApplication.prototype['proposal_matching_id'] = undefined;
/**
 * @member {Number} agent_id
 */
ContractApplication.prototype['agent_id'] = undefined;
/**
 * @member {Number} user_id
 */
ContractApplication.prototype['user_id'] = undefined;
/**
 * @member {Date} application_date
 */
ContractApplication.prototype['application_date'] = undefined;
/**
 * @member {Number} contract_price
 */
ContractApplication.prototype['contract_price'] = undefined;
/**
 * @member {String} contract_property_name
 */
ContractApplication.prototype['contract_property_name'] = undefined;
/**
 * @member {Date} contract_date
 */
ContractApplication.prototype['contract_date'] = undefined;
/**
 * @member {Date} created_at
 */
ContractApplication.prototype['created_at'] = undefined;
/**
 * @member {Date} updated_at
 */
ContractApplication.prototype['updated_at'] = undefined;
/**
 * @member {module:model/Agent} agent
 */
ContractApplication.prototype['agent'] = undefined;
/**
 * @member {module:model/User} user
 */
ContractApplication.prototype['user'] = undefined;
/**
 * @member {Array.<module:model/ContractApplicationAttachmentFile>} contract_application_attachment_files
 */
ContractApplication.prototype['contract_application_attachment_files'] = undefined;




export default ContractApplicationPatchRequest;

