/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class Period.
* @enum {}
* @readonly
*/
export default class Period {
    
        /**
         * value: "6ヶ月未満"
         * @const
         */
        "6ヶ月未満" = "6ヶ月未満";

    
        /**
         * value: "6ヶ月以上1年未満"
         * @const
         */
        "6ヶ月以上1年未満" = "6ヶ月以上1年未満";

    
        /**
         * value: "1年以上3年未満"
         * @const
         */
        "1年以上3年未満" = "1年以上3年未満";

    
        /**
         * value: "3年以上"
         * @const
         */
        "3年以上" = "3年以上";

    

    /**
    * Returns a <code>Period</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/Period} The enum <code>Period</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

