import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import InvestmentProposalBulkRegister from "./pages/users/bulk-register";
const BasicPageFrame = lazy(async () => await import("./components/Templates/BasicPageFrame"));
const EntrancePageFrame = lazy(async () => await import("./components/Templates/EntrancePageFrame"));

const Login = lazy(async () => await import("./pages/sign-in"));
const Register = lazy(async () => await import("./pages/sign-up"));
const ResetPasswordPage = lazy(async () => await import("./pages/reset-password"));
const NewPasswordPage = lazy(async () => await import("./pages/new-password"));

const AssessmentProposals = lazy(async () => await import("./pages/properties"));
const AssessmentProposalsFavorites = lazy(async () => await import("./pages/properties/favorites"));
const AssessmentProposalsProposed = lazy(async () => await import("./pages/properties/proposed"));
const AssessmentProposalsProperty = lazy(async () => await import("./pages/properties/[propertyId]"));
const AssessmentProposalsRegisterPage = lazy(async () => await import("./pages/properties/[propertyId]/register"));
const EditAssessmentProposal = lazy(async () => await import("./pages/properties/[propertyId]/edit"));

const InvestmentProposals = lazy(async () => await import("./pages/users"));
const InvestmentProposalsFavorites = lazy(async () => await import("./pages/users/favorites"));
const InvestmentProposalsProposed = lazy(async () => await import("./pages/users/proposed"));
const InvestmentProposalsUser = lazy(async () => await import("./pages/users/[userId]"));
const RegisterInvestmentProposal = lazy(async () => await import("./pages/users/[userId]/register"));
const EditInvestmentProposal = lazy(async () => await import("./pages/users/[userId]/edit"));

const Matches = lazy(async () => await import("./pages/matches"));
const MatchesUser = lazy(async () => await import("./pages/matches/[matchingId]"));

const ApplyContract = lazy(async () => await import("./pages/apply-contract"));

const Settings = lazy(async () => await import("./pages/settings"));
const SettingsUserProfile = lazy(async () => await import("./pages/settings/user"));
const SettingsCompanyProfile = lazy(async () => await import("./pages/settings/company"));

export const routerPaths = [
  {
    path: "",
    element: <BasicPageFrame />,
    children: [
      {
        path: "",
        // react router 的にリダイレクトが非推奨でも使いたい
        // TODO: top page が決まったら変更する
        element: <Navigate replace to="/users" />,
      },
    ],
  },
  {
    path: "/",
    element: <EntrancePageFrame />,
    children: [
      {
        path: "sign-up",
        element: <Register />,
      },
      {
        path: "sign-in",
        element: <Login />,
      },
      {
        path: "reset-password",
        element: <ResetPasswordPage />,
      },
      {
        path: "new-password",
        element: <NewPasswordPage />,
      },
    ],
  },
  {
    path: "/properties",
    text: "査定提案",
    element: <BasicPageFrame />,
    children: [
      {
        text: "未提案の物件",
        path: "",
        element: <AssessmentProposals />,
      },
      {
        text: "お気に入りの物件",
        path: "favorites",
        element: <AssessmentProposalsFavorites />,
      },
      {
        text: "提案済みの物件",
        path: "proposed",
        element: <AssessmentProposalsProposed />,
      },
      {
        text: "物件詳細",
        path: ":propertyId",
        element: <AssessmentProposalsProperty />,
      },
      {
        text: "査定提案 登録",
        path: ":propertyId/assessment-proposals/register",
        element: <AssessmentProposalsRegisterPage />,
      },
      {
        text: "提案の詳細",
        path: "proposed/:proposalId",
        element: <EditAssessmentProposal />,
      },
    ],
  },
  {
    path: "/users",
    text: "投資提案",
    element: <BasicPageFrame />,
    children: [
      {
        text: "未提案のユーザー",
        path: "",
        element: <InvestmentProposals />,
      },
      {
        text: "お気に入りのユーザー",
        path: "favorites",
        element: <InvestmentProposalsFavorites />,
      },
      {
        text: "提案済みのユーザー",
        path: "proposed",
        element: <InvestmentProposalsProposed />,
      },
      {
        text: "一括投資提案",
        path: "bulk-register",
        element: <InvestmentProposalBulkRegister />,
      },
      {
        text: "提案の詳細",
        path: "proposed/:proposalId",
        element: <EditInvestmentProposal />,
      },
      {
        text: "ユーザー詳細",
        path: ":userId",
        element: <InvestmentProposalsUser />,
      },
      {
        text: "物件詳細",
        path: ":userId/properties/:propertyId",
        element: <InvestmentProposalsUser />,
      },
      {
        text: "投資提案 登録",
        path: ":userId/investment-proposals/register",
        element: <RegisterInvestmentProposal />,
      },
    ],
  },
  {
    path: "/matches",
    text: "マッチしたユーザー",
    element: <BasicPageFrame />,
    children: [
      {
        text: "ユーザー一覧",
        path: "",
        element: <Matches />,
      },
      {
        text: "ユーザー詳細",
        path: "user/:userId",
        element: <MatchesUser />,
      },
    ],
  },
  {
    path: "/apply-contract",
    text: "成約申請",
    element: <BasicPageFrame />,
    children: [
      {
        text: "成約申請",
        path: "",
        element: <ApplyContract />,
      },
    ],
  },
  {
    path: "/settings",
    text: "設定",
    element: <BasicPageFrame />,
    children: [
      {
        text: "プロフィール編集",
        path: "",
        element: <Settings />,
      },
      {
        text: "プロフィール編集",
        path: "user",
        element: <SettingsUserProfile />,
      },
      {
        text: "会社情報",
        path: "company",
        element: <SettingsCompanyProfile />,
      },
    ],
  },
  {
    path: "*",
    element: <div>404 not found</div>,
  },
];
