/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class ProposalStatusEnum.
* @enum {}
* @readonly
*/
export default class ProposalStatusEnum {
    
        /**
         * value: "提案済み"
         * @const
         */
        "提案済み" = "提案済み";

    
        /**
         * value: "マッチ"
         * @const
         */
        "マッチ" = "マッチ";

    
        /**
         * value: "成約済み"
         * @const
         */
        "成約済み" = "成約済み";

    

    /**
    * Returns a <code>ProposalStatusEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/ProposalStatusEnum} The enum <code>ProposalStatusEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

