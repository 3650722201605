/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Agent from './Agent';
import AgentProfileImage from './AgentProfileImage';
import AssessmentProposal from './AssessmentProposal';
import Company from './Company';
import ContractApplication from './ContractApplication';
import InvestmentProposal from './InvestmentProposal';
import ProposalMatching from './ProposalMatching';

/**
 * The FirstRegistrationRequest model module.
 * @module model/FirstRegistrationRequest
 * @version 1.0.0
 */
class FirstRegistrationRequest {
    /**
     * Constructs a new <code>FirstRegistrationRequest</code>.
     * @alias module:model/FirstRegistrationRequest
     * @implements module:model/Agent
     * @param email {String} 
     * @param password {String} 
     * @param passwordConfirmation {String} 
     * @param familyName {String} 
     * @param firstName {String} 
     * @param phoneNumber {String} 
     */
    constructor(email, password, passwordConfirmation, familyName, firstName, phoneNumber) { 
        Agent.initialize(this);
        FirstRegistrationRequest.initialize(this, email, password, passwordConfirmation, familyName, firstName, phoneNumber);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, email, password, passwordConfirmation, familyName, firstName, phoneNumber) { 
        obj['email'] = email;
        obj['password'] = password;
        obj['password_confirmation'] = passwordConfirmation;
        obj['family_name'] = familyName;
        obj['first_name'] = firstName;
        obj['phone_number'] = phoneNumber;
    }

    /**
     * Constructs a <code>FirstRegistrationRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FirstRegistrationRequest} obj Optional instance to populate.
     * @return {module:model/FirstRegistrationRequest} The populated <code>FirstRegistrationRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FirstRegistrationRequest();
            Agent.constructFromObject(data, obj);

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('company_id')) {
                obj['company_id'] = ApiClient.convertToType(data['company_id'], 'Number');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('password_confirmation')) {
                obj['password_confirmation'] = ApiClient.convertToType(data['password_confirmation'], 'String');
            }
            if (data.hasOwnProperty('is_authenticated_by_organizer')) {
                obj['is_authenticated_by_organizer'] = ApiClient.convertToType(data['is_authenticated_by_organizer'], 'Boolean');
            }
            if (data.hasOwnProperty('is_admin')) {
                obj['is_admin'] = ApiClient.convertToType(data['is_admin'], 'Boolean');
            }
            if (data.hasOwnProperty('family_name')) {
                obj['family_name'] = ApiClient.convertToType(data['family_name'], 'String');
            }
            if (data.hasOwnProperty('first_name')) {
                obj['first_name'] = ApiClient.convertToType(data['first_name'], 'String');
            }
            if (data.hasOwnProperty('phone_number')) {
                obj['phone_number'] = ApiClient.convertToType(data['phone_number'], 'String');
            }
            if (data.hasOwnProperty('profile')) {
                obj['profile'] = ApiClient.convertToType(data['profile'], 'String');
            }
            if (data.hasOwnProperty('last_accessed_at')) {
                obj['last_accessed_at'] = ApiClient.convertToType(data['last_accessed_at'], 'Date');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('is_enabled_notification_new_user')) {
                obj['is_enabled_notification_new_user'] = ApiClient.convertToType(data['is_enabled_notification_new_user'], 'Boolean');
            }
            if (data.hasOwnProperty('is_enabled_notification_new_property')) {
                obj['is_enabled_notification_new_property'] = ApiClient.convertToType(data['is_enabled_notification_new_property'], 'Boolean');
            }
            if (data.hasOwnProperty('company')) {
                obj['company'] = Company.constructFromObject(data['company']);
            }
            if (data.hasOwnProperty('agent_profile_image')) {
                obj['agent_profile_image'] = AgentProfileImage.constructFromObject(data['agent_profile_image']);
            }
            if (data.hasOwnProperty('assessment_proposals')) {
                obj['assessment_proposals'] = ApiClient.convertToType(data['assessment_proposals'], [AssessmentProposal]);
            }
            if (data.hasOwnProperty('investment_proposals')) {
                obj['investment_proposals'] = ApiClient.convertToType(data['investment_proposals'], [InvestmentProposal]);
            }
            if (data.hasOwnProperty('proposal_matchings')) {
                obj['proposal_matchings'] = ApiClient.convertToType(data['proposal_matchings'], [ProposalMatching]);
            }
            if (data.hasOwnProperty('contract_applications')) {
                obj['contract_applications'] = ApiClient.convertToType(data['contract_applications'], [ContractApplication]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>FirstRegistrationRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>FirstRegistrationRequest</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of FirstRegistrationRequest.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
            throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
        }
        // ensure the json data is a string
        if (data['password'] && !(typeof data['password'] === 'string' || data['password'] instanceof String)) {
            throw new Error("Expected the field `password` to be a primitive type in the JSON string but got " + data['password']);
        }
        // ensure the json data is a string
        if (data['password_confirmation'] && !(typeof data['password_confirmation'] === 'string' || data['password_confirmation'] instanceof String)) {
            throw new Error("Expected the field `password_confirmation` to be a primitive type in the JSON string but got " + data['password_confirmation']);
        }
        // ensure the json data is a string
        if (data['family_name'] && !(typeof data['family_name'] === 'string' || data['family_name'] instanceof String)) {
            throw new Error("Expected the field `family_name` to be a primitive type in the JSON string but got " + data['family_name']);
        }
        // ensure the json data is a string
        if (data['first_name'] && !(typeof data['first_name'] === 'string' || data['first_name'] instanceof String)) {
            throw new Error("Expected the field `first_name` to be a primitive type in the JSON string but got " + data['first_name']);
        }
        // ensure the json data is a string
        if (data['phone_number'] && !(typeof data['phone_number'] === 'string' || data['phone_number'] instanceof String)) {
            throw new Error("Expected the field `phone_number` to be a primitive type in the JSON string but got " + data['phone_number']);
        }
        // ensure the json data is a string
        if (data['profile'] && !(typeof data['profile'] === 'string' || data['profile'] instanceof String)) {
            throw new Error("Expected the field `profile` to be a primitive type in the JSON string but got " + data['profile']);
        }
        // validate the optional field `company`
        if (data['company']) { // data not null
          Company.validateJSON(data['company']);
        }
        // validate the optional field `agent_profile_image`
        if (data['agent_profile_image']) { // data not null
          AgentProfileImage.validateJSON(data['agent_profile_image']);
        }
        if (data['assessment_proposals']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['assessment_proposals'])) {
                throw new Error("Expected the field `assessment_proposals` to be an array in the JSON data but got " + data['assessment_proposals']);
            }
            // validate the optional field `assessment_proposals` (array)
            for (const item of data['assessment_proposals']) {
                AssessmentProposal.validateJSON(item);
            };
        }
        if (data['investment_proposals']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['investment_proposals'])) {
                throw new Error("Expected the field `investment_proposals` to be an array in the JSON data but got " + data['investment_proposals']);
            }
            // validate the optional field `investment_proposals` (array)
            for (const item of data['investment_proposals']) {
                InvestmentProposal.validateJSON(item);
            };
        }
        if (data['proposal_matchings']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['proposal_matchings'])) {
                throw new Error("Expected the field `proposal_matchings` to be an array in the JSON data but got " + data['proposal_matchings']);
            }
            // validate the optional field `proposal_matchings` (array)
            for (const item of data['proposal_matchings']) {
                ProposalMatching.validateJSON(item);
            };
        }
        if (data['contract_applications']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['contract_applications'])) {
                throw new Error("Expected the field `contract_applications` to be an array in the JSON data but got " + data['contract_applications']);
            }
            // validate the optional field `contract_applications` (array)
            for (const item of data['contract_applications']) {
                ContractApplication.validateJSON(item);
            };
        }

        return true;
    }


}

FirstRegistrationRequest.RequiredProperties = ["email", "password", "password_confirmation", "family_name", "first_name", "phone_number"];

/**
 * @member {Number} id
 */
FirstRegistrationRequest.prototype['id'] = undefined;

/**
 * @member {Number} company_id
 */
FirstRegistrationRequest.prototype['company_id'] = undefined;

/**
 * @member {String} email
 */
FirstRegistrationRequest.prototype['email'] = undefined;

/**
 * @member {String} password
 */
FirstRegistrationRequest.prototype['password'] = undefined;

/**
 * @member {String} password_confirmation
 */
FirstRegistrationRequest.prototype['password_confirmation'] = undefined;

/**
 * @member {Boolean} is_authenticated_by_organizer
 */
FirstRegistrationRequest.prototype['is_authenticated_by_organizer'] = undefined;

/**
 * @member {Boolean} is_admin
 */
FirstRegistrationRequest.prototype['is_admin'] = undefined;

/**
 * @member {String} family_name
 */
FirstRegistrationRequest.prototype['family_name'] = undefined;

/**
 * @member {String} first_name
 */
FirstRegistrationRequest.prototype['first_name'] = undefined;

/**
 * @member {String} phone_number
 */
FirstRegistrationRequest.prototype['phone_number'] = undefined;

/**
 * @member {String} profile
 */
FirstRegistrationRequest.prototype['profile'] = undefined;

/**
 * @member {Date} last_accessed_at
 */
FirstRegistrationRequest.prototype['last_accessed_at'] = undefined;

/**
 * @member {Date} created_at
 */
FirstRegistrationRequest.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
FirstRegistrationRequest.prototype['updated_at'] = undefined;

/**
 * @member {Boolean} is_enabled_notification_new_user
 */
FirstRegistrationRequest.prototype['is_enabled_notification_new_user'] = undefined;

/**
 * @member {Boolean} is_enabled_notification_new_property
 */
FirstRegistrationRequest.prototype['is_enabled_notification_new_property'] = undefined;

/**
 * @member {module:model/Company} company
 */
FirstRegistrationRequest.prototype['company'] = undefined;

/**
 * @member {module:model/AgentProfileImage} agent_profile_image
 */
FirstRegistrationRequest.prototype['agent_profile_image'] = undefined;

/**
 * @member {Array.<module:model/AssessmentProposal>} assessment_proposals
 */
FirstRegistrationRequest.prototype['assessment_proposals'] = undefined;

/**
 * @member {Array.<module:model/InvestmentProposal>} investment_proposals
 */
FirstRegistrationRequest.prototype['investment_proposals'] = undefined;

/**
 * @member {Array.<module:model/ProposalMatching>} proposal_matchings
 */
FirstRegistrationRequest.prototype['proposal_matchings'] = undefined;

/**
 * @member {Array.<module:model/ContractApplication>} contract_applications
 */
FirstRegistrationRequest.prototype['contract_applications'] = undefined;


// Implement Agent interface:
/**
 * @member {Number} id
 */
Agent.prototype['id'] = undefined;
/**
 * @member {Number} company_id
 */
Agent.prototype['company_id'] = undefined;
/**
 * @member {String} email
 */
Agent.prototype['email'] = undefined;
/**
 * @member {String} password
 */
Agent.prototype['password'] = undefined;
/**
 * @member {String} password_confirmation
 */
Agent.prototype['password_confirmation'] = undefined;
/**
 * @member {Boolean} is_authenticated_by_organizer
 */
Agent.prototype['is_authenticated_by_organizer'] = undefined;
/**
 * @member {Boolean} is_admin
 */
Agent.prototype['is_admin'] = undefined;
/**
 * @member {String} family_name
 */
Agent.prototype['family_name'] = undefined;
/**
 * @member {String} first_name
 */
Agent.prototype['first_name'] = undefined;
/**
 * @member {String} phone_number
 */
Agent.prototype['phone_number'] = undefined;
/**
 * @member {String} profile
 */
Agent.prototype['profile'] = undefined;
/**
 * @member {Date} last_accessed_at
 */
Agent.prototype['last_accessed_at'] = undefined;
/**
 * @member {Date} created_at
 */
Agent.prototype['created_at'] = undefined;
/**
 * @member {Date} updated_at
 */
Agent.prototype['updated_at'] = undefined;
/**
 * @member {Boolean} is_enabled_notification_new_user
 */
Agent.prototype['is_enabled_notification_new_user'] = undefined;
/**
 * @member {Boolean} is_enabled_notification_new_property
 */
Agent.prototype['is_enabled_notification_new_property'] = undefined;
/**
 * @member {module:model/Company} company
 */
Agent.prototype['company'] = undefined;
/**
 * @member {module:model/AgentProfileImage} agent_profile_image
 */
Agent.prototype['agent_profile_image'] = undefined;
/**
 * @member {Array.<module:model/AssessmentProposal>} assessment_proposals
 */
Agent.prototype['assessment_proposals'] = undefined;
/**
 * @member {Array.<module:model/InvestmentProposal>} investment_proposals
 */
Agent.prototype['investment_proposals'] = undefined;
/**
 * @member {Array.<module:model/ProposalMatching>} proposal_matchings
 */
Agent.prototype['proposal_matchings'] = undefined;
/**
 * @member {Array.<module:model/ContractApplication>} contract_applications
 */
Agent.prototype['contract_applications'] = undefined;




export default FirstRegistrationRequest;

