/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AgentProfileImage model module.
 * @module model/AgentProfileImage
 * @version 1.0.0
 */
class AgentProfileImage {
    /**
     * Constructs a new <code>AgentProfileImage</code>.
     * @alias module:model/AgentProfileImage
     */
    constructor() { 
        
        AgentProfileImage.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AgentProfileImage</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AgentProfileImage} obj Optional instance to populate.
     * @return {module:model/AgentProfileImage} The populated <code>AgentProfileImage</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AgentProfileImage();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('agent_id')) {
                obj['agent_id'] = ApiClient.convertToType(data['agent_id'], 'Number');
            }
            if (data.hasOwnProperty('object_storage_path')) {
                obj['object_storage_path'] = ApiClient.convertToType(data['object_storage_path'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('image_url')) {
                obj['image_url'] = ApiClient.convertToType(data['image_url'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AgentProfileImage</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AgentProfileImage</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['object_storage_path'] && !(typeof data['object_storage_path'] === 'string' || data['object_storage_path'] instanceof String)) {
            throw new Error("Expected the field `object_storage_path` to be a primitive type in the JSON string but got " + data['object_storage_path']);
        }
        // ensure the json data is a string
        if (data['image_url'] && !(typeof data['image_url'] === 'string' || data['image_url'] instanceof String)) {
            throw new Error("Expected the field `image_url` to be a primitive type in the JSON string but got " + data['image_url']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
AgentProfileImage.prototype['id'] = undefined;

/**
 * @member {Number} agent_id
 */
AgentProfileImage.prototype['agent_id'] = undefined;

/**
 * @member {String} object_storage_path
 */
AgentProfileImage.prototype['object_storage_path'] = undefined;

/**
 * @member {Date} created_at
 */
AgentProfileImage.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
AgentProfileImage.prototype['updated_at'] = undefined;

/**
 * @member {String} image_url
 */
AgentProfileImage.prototype['image_url'] = undefined;






export default AgentProfileImage;

