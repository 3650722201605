import Box from "@mui/material/Box";
import MUIModal from "@mui/material/Modal";
import * as React from "react";
import Button from "../Atoms/Button";

type VariantType = "noButton" | "oneButton" | "twoButton";

interface ModalProps {
  /**
   * A function that is executed when the button is clicked.
   */
  onClickProcessButton: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined;
  onClickCancelButton?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined;
  /**
   * 見える｜見えない
   */
  isDisplayed: boolean;
  /**
   * Modalの中のテキスト
   * */
  insideText: string;
  /**
   * Process Buttonの中のテキスト
   * */
  processBLabel: string;

  /**
   * Cancel Buttonの中のテキスト
   * */
  cancelBLabel: string;
  /**
   * "no-button"|"one-button"|"two-button"
   */
  variant: VariantType;
}

const modalStyle = {
  width: 461,
  background: "#EEE",
  borderRadius: "16px",
};

const textStyle: React.CSSProperties = {
  fontFamily: "Noto Sans JP",
  fontSize: 18,
  fontWeight: 400,
  letterSpacing: 1,
  color: "#25293A",
  lineHeight: "26px",
  whiteSpace: "pre-line",
};

/********************************************* */
const ButtonCheck = (buttonCheck: ModalProps) => {
  const buttonModal = {
    ...modalStyle,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "33.5px 76px",
  };

  const noButtonModal = {
    ...modalStyle,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "80.5px 76px",
  };

  const noBTextStyle: React.CSSProperties = {
    ...textStyle,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  };

  const BTextStyle: React.CSSProperties = {
    ...textStyle,
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  };

  switch (buttonCheck.variant) {
    case "noButton":
      return (
        <Box sx={noButtonModal}>
          <span style={noBTextStyle}>{buttonCheck.insideText}</span>
        </Box>
      );
    case "oneButton":
      return (
        <Box sx={buttonModal}>
          <span style={BTextStyle}>{buttonCheck.insideText}</span>
          <div style={{ paddingTop: 33, height: 61 }}>
            <Button size="XLarge" label={buttonCheck.cancelBLabel} onClick={buttonCheck.onClickProcessButton} />
          </div>
        </Box>
      );
    case "twoButton":
    default:
      return (
        <Box sx={buttonModal}>
          <span style={BTextStyle}>{buttonCheck.insideText}</span>
          <div
            style={{
              width: "100%",
              height: 61,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              paddingTop: 33,
            }}
          >
            <Button size="XLarge" label={buttonCheck.processBLabel} onClick={buttonCheck.onClickProcessButton} />
            <Button
              size="XLarge"
              variant="outlined"
              label={buttonCheck.cancelBLabel}
              onClick={buttonCheck.onClickCancelButton}
            />
          </div>
        </Box>
      );
  }
};

const Modal = (props: ModalProps) => {
  return (
    <MUIModal open={props.isDisplayed} aria-describedby="modal--description">
      <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <center>{ButtonCheck(props)}</center>
      </Box>
    </MUIModal>
  );
};

export default Modal;
