import type { PropsWithChildren } from "react";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { PropallyAgentV1Api, ProposalMatchingUnreadCountResponse } from "../../api/generated/src";
import { UnreadMatchingCount } from "../../view_models/unreadMatchingCount";

const fetchUnreadCount = async (): Promise<ProposalMatchingUnreadCountResponse> => {
  return new Promise((resolve, reject) => {
    new PropallyAgentV1Api().agentV1ProposalMatchingUnreadCountGet(
      (error: object, data: ProposalMatchingUnreadCountResponse, response: object) => {
        if (error) {
          reject(response);
        } else {
          resolve(data);
        }
      }
    );
  });
};

export const UnreadMatchingProvider: React.FC<PropsWithChildren> = (props) => {
  const [unreadCount, setUnreadCount] = useRecoilState(UnreadMatchingCount);

  useEffect(() => {
    fetchUnreadCount()
      .then((response) => {
        if (response.unread_count !== unreadCount) {
          setUnreadCount(response);
        }
      })
      .catch((error) => {
        if (error.statusCode !== 401) {
          console.error(error);
        }
      });
  }, []);

  return <>{props.children}</>;
};
