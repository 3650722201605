/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Agent from '../model/Agent';
import AgentProfileImage from '../model/AgentProfileImage';
import AgentRequest from '../model/AgentRequest';
import AgentUserMemo from '../model/AgentUserMemo';
import AgentUserSalesNote from '../model/AgentUserSalesNote';
import AgentV1AgentGet404Response from '../model/AgentV1AgentGet404Response';
import AgentV1AgentProfilePost404Response from '../model/AgentV1AgentProfilePost404Response';
import AgentV1LoginPost401Response from '../model/AgentV1LoginPost401Response';
import AgentV1LoginPostRequest from '../model/AgentV1LoginPostRequest';
import AgentV1LogoutPost200Response from '../model/AgentV1LogoutPost200Response';
import AgentV1LogoutPost401Response from '../model/AgentV1LogoutPost401Response';
import AgentV1NewPasswordsPost400Response from '../model/AgentV1NewPasswordsPost400Response';
import AgentV1NewPasswordsPostRequest from '../model/AgentV1NewPasswordsPostRequest';
import AgentV1PasswordResetsPostRequest from '../model/AgentV1PasswordResetsPostRequest';
import AgentV1PropertiesPropertyIdFavoriteDelete422Response from '../model/AgentV1PropertiesPropertyIdFavoriteDelete422Response';
import AgentV1PropertiesPropertyIdFavoritePatch422Response from '../model/AgentV1PropertiesPropertyIdFavoritePatch422Response';
import AssessmentProposal from '../model/AssessmentProposal';
import AssessmentProposalRequest from '../model/AssessmentProposalRequest';
import Company from '../model/Company';
import CompanyLogoImage from '../model/CompanyLogoImage';
import CompanyRequest from '../model/CompanyRequest';
import ContractApplication from '../model/ContractApplication';
import ContractApplicationPatchRequest from '../model/ContractApplicationPatchRequest';
import ContractApplicationPostRequest from '../model/ContractApplicationPostRequest';
import FirstRegistrationRequest from '../model/FirstRegistrationRequest';
import Id from '../model/Id';
import IdName from '../model/IdName';
import InvalidRequestMessage from '../model/InvalidRequestMessage';
import InvestmentProposal from '../model/InvestmentProposal';
import InvestmentProposalRequest from '../model/InvestmentProposalRequest';
import Property from '../model/Property';
import PropertySearchRequest from '../model/PropertySearchRequest';
import ProposalMatching from '../model/ProposalMatching';
import ProposalMatchingUnreadCountResponse from '../model/ProposalMatchingUnreadCountResponse';
import ResponseMessage from '../model/ResponseMessage';
import User from '../model/User';
import UserSearchRequest from '../model/UserSearchRequest';

/**
* PropallyAgentV1 service.
* @module api/PropallyAgentV1Api
* @version 1.0.0
*/
export default class PropallyAgentV1Api {

    /**
    * Constructs a new PropallyAgentV1Api. 
    * @alias module:api/PropallyAgentV1Api
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the agentV1AgentGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1AgentGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Agent} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * エージェント情報1件取得
     * @param {module:api/PropallyAgentV1Api~agentV1AgentGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Agent}
     */
    agentV1AgentGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Agent;
      return this.apiClient.callApi(
        '/agent/v1/agent', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1AgentPatch operation.
     * @callback module:api/PropallyAgentV1Api~agentV1AgentPatchCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Agent} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * エージェント情報更新
     * @param {Object} opts Optional parameters
     * @param {module:model/AgentRequest} [agentRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1AgentPatchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Agent}
     */
    agentV1AgentPatch(opts, callback) {
      opts = opts || {};
      let postBody = opts['agentRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Agent;
      return this.apiClient.callApi(
        '/agent/v1/agent', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1AgentProfilePost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1AgentProfilePostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AgentProfileImage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * エージェントプロフィール画像登録
     * @param {Object} opts Optional parameters
     * @param {File} [imageBinary] 
     * @param {module:api/PropallyAgentV1Api~agentV1AgentProfilePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AgentProfileImage}
     */
    agentV1AgentProfilePost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'image_binary': opts['imageBinary']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = AgentProfileImage;
      return this.apiClient.callApi(
        '/agent/v1/agent/profile', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1AssessmentProposalsAssessmentProposalIdDelete operation.
     * @callback module:api/PropallyAgentV1Api~agentV1AssessmentProposalsAssessmentProposalIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 査定提案削除
     * @param {Number} assessmentProposalId 査定提案 ID
     * @param {module:api/PropallyAgentV1Api~agentV1AssessmentProposalsAssessmentProposalIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1AssessmentProposalsAssessmentProposalIdDelete(assessmentProposalId, callback) {
      let postBody = null;
      // verify the required parameter 'assessmentProposalId' is set
      if (assessmentProposalId === undefined || assessmentProposalId === null) {
        throw new Error("Missing the required parameter 'assessmentProposalId' when calling agentV1AssessmentProposalsAssessmentProposalIdDelete");
      }

      let pathParams = {
        'assessment_proposal_id': assessmentProposalId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/assessment_proposals/{assessment_proposal_id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1AssessmentProposalsAssessmentProposalIdFilesPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1AssessmentProposalsAssessmentProposalIdFilesPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 査定提案添付ファイル登録
     * @param {Number} assessmentProposalId 査定提案 ID
     * @param {Object} opts Optional parameters
     * @param {File} [imageBinary] 
     * @param {module:api/PropallyAgentV1Api~agentV1AssessmentProposalsAssessmentProposalIdFilesPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1AssessmentProposalsAssessmentProposalIdFilesPost(assessmentProposalId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'assessmentProposalId' is set
      if (assessmentProposalId === undefined || assessmentProposalId === null) {
        throw new Error("Missing the required parameter 'assessmentProposalId' when calling agentV1AssessmentProposalsAssessmentProposalIdFilesPost");
      }

      let pathParams = {
        'assessment_proposal_id': assessmentProposalId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'image_binary': opts['imageBinary']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/assessment_proposals/{assessment_proposal_id}/files', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1AssessmentProposalsAssessmentProposalIdGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1AssessmentProposalsAssessmentProposalIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AssessmentProposal} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 査定提案1件取得
     * @param {Number} assessmentProposalId 査定提案 ID
     * @param {module:api/PropallyAgentV1Api~agentV1AssessmentProposalsAssessmentProposalIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AssessmentProposal}
     */
    agentV1AssessmentProposalsAssessmentProposalIdGet(assessmentProposalId, callback) {
      let postBody = null;
      // verify the required parameter 'assessmentProposalId' is set
      if (assessmentProposalId === undefined || assessmentProposalId === null) {
        throw new Error("Missing the required parameter 'assessmentProposalId' when calling agentV1AssessmentProposalsAssessmentProposalIdGet");
      }

      let pathParams = {
        'assessment_proposal_id': assessmentProposalId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = AssessmentProposal;
      return this.apiClient.callApi(
        '/agent/v1/assessment_proposals/{assessment_proposal_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1AssessmentProposalsAssessmentProposalIdPatch operation.
     * @callback module:api/PropallyAgentV1Api~agentV1AssessmentProposalsAssessmentProposalIdPatchCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 査定提案更新 ※リクエストボディのproperty_idは不要
     * @param {Number} assessmentProposalId 査定提案 ID
     * @param {Object} opts Optional parameters
     * @param {module:model/AssessmentProposalRequest} [assessmentProposalRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1AssessmentProposalsAssessmentProposalIdPatchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1AssessmentProposalsAssessmentProposalIdPatch(assessmentProposalId, opts, callback) {
      opts = opts || {};
      let postBody = opts['assessmentProposalRequest'];
      // verify the required parameter 'assessmentProposalId' is set
      if (assessmentProposalId === undefined || assessmentProposalId === null) {
        throw new Error("Missing the required parameter 'assessmentProposalId' when calling agentV1AssessmentProposalsAssessmentProposalIdPatch");
      }

      let pathParams = {
        'assessment_proposal_id': assessmentProposalId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/assessment_proposals/{assessment_proposal_id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1AssessmentProposalsGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1AssessmentProposalsGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AssessmentProposal>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 査定提案一覧取得
     * @param {module:api/PropallyAgentV1Api~agentV1AssessmentProposalsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AssessmentProposal>}
     */
    agentV1AssessmentProposalsGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [AssessmentProposal];
      return this.apiClient.callApi(
        '/agent/v1/assessment_proposals', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1AssessmentProposalsPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1AssessmentProposalsPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Id} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 査定提案登録
     * @param {Object} opts Optional parameters
     * @param {module:model/AssessmentProposalRequest} [assessmentProposalRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1AssessmentProposalsPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Id}
     */
    agentV1AssessmentProposalsPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['assessmentProposalRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Id;
      return this.apiClient.callApi(
        '/agent/v1/assessment_proposals', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1AssessmentProposalsSearchPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1AssessmentProposalsSearchPostCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AssessmentProposal>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 物件検索
     * @param {Object} opts Optional parameters
     * @param {module:model/PropertySearchRequest} [propertySearchRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1AssessmentProposalsSearchPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AssessmentProposal>}
     */
    agentV1AssessmentProposalsSearchPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['propertySearchRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [AssessmentProposal];
      return this.apiClient.callApi(
        '/agent/v1/assessment_proposals/search', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1CompanyGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1CompanyGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Company} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 企業情報取得
     * @param {module:api/PropallyAgentV1Api~agentV1CompanyGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Company}
     */
    agentV1CompanyGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Company;
      return this.apiClient.callApi(
        '/agent/v1/company', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1CompanyLogoImagePost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1CompanyLogoImagePostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompanyLogoImage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 企業ロゴ画像登録
     * @param {Object} opts Optional parameters
     * @param {File} [imageBinary] 
     * @param {module:api/PropallyAgentV1Api~agentV1CompanyLogoImagePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CompanyLogoImage}
     */
    agentV1CompanyLogoImagePost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'image_binary': opts['imageBinary']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = CompanyLogoImage;
      return this.apiClient.callApi(
        '/agent/v1/company_logo_image', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1CompanyPatch operation.
     * @callback module:api/PropallyAgentV1Api~agentV1CompanyPatchCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Company} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 企業情報更新
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyRequest} [companyRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1CompanyPatchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Company}
     */
    agentV1CompanyPatch(opts, callback) {
      opts = opts || {};
      let postBody = opts['companyRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Company;
      return this.apiClient.callApi(
        '/agent/v1/company', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1ContractApplicationsContractApplicationIdFilesPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1ContractApplicationsContractApplicationIdFilesPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 成約申請添付ファイル登録
     * @param {Number} contractApplicationId 成約申請 ID
     * @param {Object} opts Optional parameters
     * @param {File} [imageBinary] 
     * @param {module:api/PropallyAgentV1Api~agentV1ContractApplicationsContractApplicationIdFilesPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1ContractApplicationsContractApplicationIdFilesPost(contractApplicationId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'contractApplicationId' is set
      if (contractApplicationId === undefined || contractApplicationId === null) {
        throw new Error("Missing the required parameter 'contractApplicationId' when calling agentV1ContractApplicationsContractApplicationIdFilesPost");
      }

      let pathParams = {
        'contract_application_id': contractApplicationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'image_binary': opts['imageBinary']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/contract_applications/{contract_application_id}/files', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1ContractApplicationsContractApplicationIdPatch operation.
     * @callback module:api/PropallyAgentV1Api~agentV1ContractApplicationsContractApplicationIdPatchCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 成約申請更新
     * @param {Number} contractApplicationId 成約申請 ID
     * @param {Object} opts Optional parameters
     * @param {module:model/ContractApplicationPatchRequest} [contractApplicationPatchRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1ContractApplicationsContractApplicationIdPatchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1ContractApplicationsContractApplicationIdPatch(contractApplicationId, opts, callback) {
      opts = opts || {};
      let postBody = opts['contractApplicationPatchRequest'];
      // verify the required parameter 'contractApplicationId' is set
      if (contractApplicationId === undefined || contractApplicationId === null) {
        throw new Error("Missing the required parameter 'contractApplicationId' when calling agentV1ContractApplicationsContractApplicationIdPatch");
      }

      let pathParams = {
        'contract_application_id': contractApplicationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/contract_applications/{contract_application_id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1ContractApplicationsGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1ContractApplicationsGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ContractApplication>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 成約申請一覧取得
     * @param {module:api/PropallyAgentV1Api~agentV1ContractApplicationsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ContractApplication>}
     */
    agentV1ContractApplicationsGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ContractApplication];
      return this.apiClient.callApi(
        '/agent/v1/contract_applications', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1ContractApplicationsPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1ContractApplicationsPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 成約申請登録
     * @param {Object} opts Optional parameters
     * @param {module:model/ContractApplicationPostRequest} [contractApplicationPostRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1ContractApplicationsPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1ContractApplicationsPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['contractApplicationPostRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/contract_applications', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1FavoritedPropertiesGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1FavoritedPropertiesGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Property>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * お気に入り物件一覧取得
     * @param {module:api/PropallyAgentV1Api~agentV1FavoritedPropertiesGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Property>}
     */
    agentV1FavoritedPropertiesGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Property];
      return this.apiClient.callApi(
        '/agent/v1/favorited/properties', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1FavoritedUsersGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1FavoritedUsersGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/User>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * お気に入りユーザ一覧取得
     * @param {module:api/PropallyAgentV1Api~agentV1FavoritedUsersGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/User>}
     */
    agentV1FavoritedUsersGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [User];
      return this.apiClient.callApi(
        '/agent/v1/favorited/users', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1InvestmentProposalsGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1InvestmentProposalsGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/InvestmentProposal>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * ユーザー情報取得
     * @param {module:api/PropallyAgentV1Api~agentV1InvestmentProposalsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/InvestmentProposal>}
     */
    agentV1InvestmentProposalsGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [InvestmentProposal];
      return this.apiClient.callApi(
        '/agent/v1/investment_proposals', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1InvestmentProposalsInvestmentProposalIdDelete operation.
     * @callback module:api/PropallyAgentV1Api~agentV1InvestmentProposalsInvestmentProposalIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 投資提案削除
     * @param {Number} investmentProposalId 投資提案 ID
     * @param {module:api/PropallyAgentV1Api~agentV1InvestmentProposalsInvestmentProposalIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1InvestmentProposalsInvestmentProposalIdDelete(investmentProposalId, callback) {
      let postBody = null;
      // verify the required parameter 'investmentProposalId' is set
      if (investmentProposalId === undefined || investmentProposalId === null) {
        throw new Error("Missing the required parameter 'investmentProposalId' when calling agentV1InvestmentProposalsInvestmentProposalIdDelete");
      }

      let pathParams = {
        'investment_proposal_id': investmentProposalId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/investment_proposals/{investment_proposal_id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1InvestmentProposalsInvestmentProposalIdFilesPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1InvestmentProposalsInvestmentProposalIdFilesPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 投資提案添付ファイル登録
     * @param {Number} investmentProposalId 投資提案 ID
     * @param {Object} opts Optional parameters
     * @param {File} [imageBinary] 
     * @param {module:api/PropallyAgentV1Api~agentV1InvestmentProposalsInvestmentProposalIdFilesPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1InvestmentProposalsInvestmentProposalIdFilesPost(investmentProposalId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'investmentProposalId' is set
      if (investmentProposalId === undefined || investmentProposalId === null) {
        throw new Error("Missing the required parameter 'investmentProposalId' when calling agentV1InvestmentProposalsInvestmentProposalIdFilesPost");
      }

      let pathParams = {
        'investment_proposal_id': investmentProposalId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'image_binary': opts['imageBinary']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/investment_proposals/{investment_proposal_id}/files', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1InvestmentProposalsInvestmentProposalIdGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1InvestmentProposalsInvestmentProposalIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InvestmentProposal} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 投資提案1件取得
     * @param {Number} investmentProposalId 投資提案 ID
     * @param {module:api/PropallyAgentV1Api~agentV1InvestmentProposalsInvestmentProposalIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InvestmentProposal}
     */
    agentV1InvestmentProposalsInvestmentProposalIdGet(investmentProposalId, callback) {
      let postBody = null;
      // verify the required parameter 'investmentProposalId' is set
      if (investmentProposalId === undefined || investmentProposalId === null) {
        throw new Error("Missing the required parameter 'investmentProposalId' when calling agentV1InvestmentProposalsInvestmentProposalIdGet");
      }

      let pathParams = {
        'investment_proposal_id': investmentProposalId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InvestmentProposal;
      return this.apiClient.callApi(
        '/agent/v1/investment_proposals/{investment_proposal_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1InvestmentProposalsInvestmentProposalIdPatch operation.
     * @callback module:api/PropallyAgentV1Api~agentV1InvestmentProposalsInvestmentProposalIdPatchCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 投資提案更新 ※リクエストボディのuser_idは不要
     * @param {Number} investmentProposalId 投資提案 ID
     * @param {Object} opts Optional parameters
     * @param {module:model/InvestmentProposalRequest} [investmentProposalRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1InvestmentProposalsInvestmentProposalIdPatchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1InvestmentProposalsInvestmentProposalIdPatch(investmentProposalId, opts, callback) {
      opts = opts || {};
      let postBody = opts['investmentProposalRequest'];
      // verify the required parameter 'investmentProposalId' is set
      if (investmentProposalId === undefined || investmentProposalId === null) {
        throw new Error("Missing the required parameter 'investmentProposalId' when calling agentV1InvestmentProposalsInvestmentProposalIdPatch");
      }

      let pathParams = {
        'investment_proposal_id': investmentProposalId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/investment_proposals/{investment_proposal_id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1InvestmentProposalsPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1InvestmentProposalsPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Id} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 投資提案登録
     * @param {Object} opts Optional parameters
     * @param {module:model/InvestmentProposalRequest} [investmentProposalRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1InvestmentProposalsPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Id}
     */
    agentV1InvestmentProposalsPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['investmentProposalRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Id;
      return this.apiClient.callApi(
        '/agent/v1/investment_proposals', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1InvestmentProposalsSearchPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1InvestmentProposalsSearchPostCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/InvestmentProposal>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * ユーザ検索
     * @param {Object} opts Optional parameters
     * @param {module:model/UserSearchRequest} [userSearchRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1InvestmentProposalsSearchPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/InvestmentProposal>}
     */
    agentV1InvestmentProposalsSearchPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['userSearchRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [InvestmentProposal];
      return this.apiClient.callApi(
        '/agent/v1/investment_proposals/search', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1LoginPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1LoginPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Agent} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * ログイン
     * @param {Object} opts Optional parameters
     * @param {module:model/AgentV1LoginPostRequest} [agentV1LoginPostRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1LoginPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Agent}
     */
    agentV1LoginPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['agentV1LoginPostRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Agent;
      return this.apiClient.callApi(
        '/agent/v1/login', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1LogoutPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1LogoutPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AgentV1LogoutPost200Response} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * ログアウト
     * @param {module:api/PropallyAgentV1Api~agentV1LogoutPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AgentV1LogoutPost200Response}
     */
    agentV1LogoutPost(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = AgentV1LogoutPost200Response;
      return this.apiClient.callApi(
        '/agent/v1/logout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1MasterDataPrefecturesGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1MasterDataPrefecturesGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<String>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 都道府県一覧
     * @param {module:api/PropallyAgentV1Api~agentV1MasterDataPrefecturesGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<String>}
     */
    agentV1MasterDataPrefecturesGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ['String'];
      return this.apiClient.callApi(
        '/agent/v1/master_data/prefectures', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<String>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 町丁目一覧
     * @param {String} prefectureName 都道府県名
     * @param {String} cityName 市区町村名
     * @param {module:api/PropallyAgentV1Api~agentV1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<String>}
     */
    agentV1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGet(prefectureName, cityName, callback) {
      let postBody = null;
      // verify the required parameter 'prefectureName' is set
      if (prefectureName === undefined || prefectureName === null) {
        throw new Error("Missing the required parameter 'prefectureName' when calling agentV1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGet");
      }
      // verify the required parameter 'cityName' is set
      if (cityName === undefined || cityName === null) {
        throw new Error("Missing the required parameter 'cityName' when calling agentV1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGet");
      }

      let pathParams = {
        'prefecture_name': prefectureName,
        'city_name': cityName
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ['String'];
      return this.apiClient.callApi(
        '/agent/v1/master_data/prefectures/{prefecture_name}/cities/{city_name}/towns', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1MasterDataPrefecturesPrefectureNameCitiesGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1MasterDataPrefecturesPrefectureNameCitiesGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<String>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 市区町村一覧
     * @param {String} prefectureName 都道府県名
     * @param {module:api/PropallyAgentV1Api~agentV1MasterDataPrefecturesPrefectureNameCitiesGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<String>}
     */
    agentV1MasterDataPrefecturesPrefectureNameCitiesGet(prefectureName, callback) {
      let postBody = null;
      // verify the required parameter 'prefectureName' is set
      if (prefectureName === undefined || prefectureName === null) {
        throw new Error("Missing the required parameter 'prefectureName' when calling agentV1MasterDataPrefecturesPrefectureNameCitiesGet");
      }

      let pathParams = {
        'prefecture_name': prefectureName
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ['String'];
      return this.apiClient.callApi(
        '/agent/v1/master_data/prefectures/{prefecture_name}/cities', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1MasterDataResidenceTypesGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1MasterDataResidenceTypesGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/IdName>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 居住タイプマスタ取得
     * @param {module:api/PropallyAgentV1Api~agentV1MasterDataResidenceTypesGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/IdName>}
     */
    agentV1MasterDataResidenceTypesGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [IdName];
      return this.apiClient.callApi(
        '/agent/v1/master_data/residence_types', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1NewPasswordsPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1NewPasswordsPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * リセット後、新しいパスワードの登録
     * @param {Object} opts Optional parameters
     * @param {module:model/AgentV1NewPasswordsPostRequest} [agentV1NewPasswordsPostRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1NewPasswordsPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1NewPasswordsPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['agentV1NewPasswordsPostRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/new_passwords', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1PasswordResetsPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1PasswordResetsPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * パスワードリセット
     * @param {Object} opts Optional parameters
     * @param {module:model/AgentV1PasswordResetsPostRequest} [agentV1PasswordResetsPostRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1PasswordResetsPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1PasswordResetsPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['agentV1PasswordResetsPostRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/password_resets', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1PropertiesGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1PropertiesGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Property>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * すべての物件一覧取得
     * @param {module:api/PropallyAgentV1Api~agentV1PropertiesGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Property>}
     */
    agentV1PropertiesGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Property];
      return this.apiClient.callApi(
        '/agent/v1/properties', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1PropertiesPropertyIdFavoriteDelete operation.
     * @callback module:api/PropallyAgentV1Api~agentV1PropertiesPropertyIdFavoriteDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * お気に入り物件解除
     * @param {Number} propertyId 物件 ID
     * @param {module:api/PropallyAgentV1Api~agentV1PropertiesPropertyIdFavoriteDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1PropertiesPropertyIdFavoriteDelete(propertyId, callback) {
      let postBody = null;
      // verify the required parameter 'propertyId' is set
      if (propertyId === undefined || propertyId === null) {
        throw new Error("Missing the required parameter 'propertyId' when calling agentV1PropertiesPropertyIdFavoriteDelete");
      }

      let pathParams = {
        'property_id': propertyId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/properties/{property_id}/favorite', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1PropertiesPropertyIdFavoritePatch operation.
     * @callback module:api/PropallyAgentV1Api~agentV1PropertiesPropertyIdFavoritePatchCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * お気に入り物件登録
     * @param {Number} propertyId 物件 ID
     * @param {module:api/PropallyAgentV1Api~agentV1PropertiesPropertyIdFavoritePatchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1PropertiesPropertyIdFavoritePatch(propertyId, callback) {
      let postBody = null;
      // verify the required parameter 'propertyId' is set
      if (propertyId === undefined || propertyId === null) {
        throw new Error("Missing the required parameter 'propertyId' when calling agentV1PropertiesPropertyIdFavoritePatch");
      }

      let pathParams = {
        'property_id': propertyId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/properties/{property_id}/favorite', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1PropertiesPropertyIdGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1PropertiesPropertyIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Property} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 物件詳細1件取得
     * @param {Number} propertyId 物件 ID
     * @param {module:api/PropallyAgentV1Api~agentV1PropertiesPropertyIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Property}
     */
    agentV1PropertiesPropertyIdGet(propertyId, callback) {
      let postBody = null;
      // verify the required parameter 'propertyId' is set
      if (propertyId === undefined || propertyId === null) {
        throw new Error("Missing the required parameter 'propertyId' when calling agentV1PropertiesPropertyIdGet");
      }

      let pathParams = {
        'property_id': propertyId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Property;
      return this.apiClient.callApi(
        '/agent/v1/properties/{property_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1PropertiesSearchPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1PropertiesSearchPostCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Property>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 物件検索
     * @param {Object} opts Optional parameters
     * @param {module:model/PropertySearchRequest} [propertySearchRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1PropertiesSearchPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Property>}
     */
    agentV1PropertiesSearchPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['propertySearchRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [Property];
      return this.apiClient.callApi(
        '/agent/v1/properties/search', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1ProposalMatchingUnreadCountGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1ProposalMatchingUnreadCountGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProposalMatchingUnreadCountResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * マッチしたオーナーの未読数取得
     * @param {module:api/PropallyAgentV1Api~agentV1ProposalMatchingUnreadCountGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProposalMatchingUnreadCountResponse}
     */
    agentV1ProposalMatchingUnreadCountGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProposalMatchingUnreadCountResponse;
      return this.apiClient.callApi(
        '/agent/v1/proposal_matching_unread_count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1ProposalMatchingsGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1ProposalMatchingsGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ProposalMatching>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * マッチしたオーナー一覧取得
     * @param {module:api/PropallyAgentV1Api~agentV1ProposalMatchingsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ProposalMatching>}
     */
    agentV1ProposalMatchingsGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProposalMatching];
      return this.apiClient.callApi(
        '/agent/v1/proposal_matchings', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1ProposalMatchingsProposalMatchingIdGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1ProposalMatchingsProposalMatchingIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProposalMatching} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * (ユーザ連絡先含む)提案マッチング情報1件取得
     * @param {Number} proposalMatchingId 提案マッチング ID
     * @param {module:api/PropallyAgentV1Api~agentV1ProposalMatchingsProposalMatchingIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProposalMatching}
     */
    agentV1ProposalMatchingsProposalMatchingIdGet(proposalMatchingId, callback) {
      let postBody = null;
      // verify the required parameter 'proposalMatchingId' is set
      if (proposalMatchingId === undefined || proposalMatchingId === null) {
        throw new Error("Missing the required parameter 'proposalMatchingId' when calling agentV1ProposalMatchingsProposalMatchingIdGet");
      }

      let pathParams = {
        'proposal_matching_id': proposalMatchingId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProposalMatching;
      return this.apiClient.callApi(
        '/agent/v1/proposal_matchings/{proposal_matching_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1ProposalMatchingsProposalMatchingIdReadPatch operation.
     * @callback module:api/PropallyAgentV1Api~agentV1ProposalMatchingsProposalMatchingIdReadPatchCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * マッチしたオーナーのエージェント既読処理
     * @param {Number} proposalMatchingId 提案マッチング ID
     * @param {module:api/PropallyAgentV1Api~agentV1ProposalMatchingsProposalMatchingIdReadPatchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1ProposalMatchingsProposalMatchingIdReadPatch(proposalMatchingId, callback) {
      let postBody = null;
      // verify the required parameter 'proposalMatchingId' is set
      if (proposalMatchingId === undefined || proposalMatchingId === null) {
        throw new Error("Missing the required parameter 'proposalMatchingId' when calling agentV1ProposalMatchingsProposalMatchingIdReadPatch");
      }

      let pathParams = {
        'proposal_matching_id': proposalMatchingId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/proposal_matchings/{proposal_matching_id}/read', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1RegistrationsPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1RegistrationsPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 企業・エージェントの初回登録 ※これだけではログインできずadmin側で承認処理が必要
     * @param {Object} opts Optional parameters
     * @param {module:model/FirstRegistrationRequest} [firstRegistrationRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1RegistrationsPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1RegistrationsPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['firstRegistrationRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/registrations', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1SalesNotesUsersGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1SalesNotesUsersGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AgentUserSalesNote>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 営業記録情報一覧取得
     * @param {module:api/PropallyAgentV1Api~agentV1SalesNotesUsersGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AgentUserSalesNote>}
     */
    agentV1SalesNotesUsersGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [AgentUserSalesNote];
      return this.apiClient.callApi(
        '/agent/v1/sales_notes/users', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1SalesNotesUsersUserIdPatch operation.
     * @callback module:api/PropallyAgentV1Api~agentV1SalesNotesUsersUserIdPatchCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AgentUserSalesNote} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 営業記録情報登録・更新
     * @param {Number} userId ユーザ ID
     * @param {module:model/AgentUserSalesNote} agentUserSalesNote 
     * @param {module:api/PropallyAgentV1Api~agentV1SalesNotesUsersUserIdPatchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AgentUserSalesNote}
     */
    agentV1SalesNotesUsersUserIdPatch(userId, agentUserSalesNote, callback) {
      let postBody = agentUserSalesNote;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling agentV1SalesNotesUsersUserIdPatch");
      }
      // verify the required parameter 'agentUserSalesNote' is set
      if (agentUserSalesNote === undefined || agentUserSalesNote === null) {
        throw new Error("Missing the required parameter 'agentUserSalesNote' when calling agentV1SalesNotesUsersUserIdPatch");
      }

      let pathParams = {
        'user_id': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = AgentUserSalesNote;
      return this.apiClient.callApi(
        '/agent/v1/sales_notes/users/{user_id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1UsersGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1UsersGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/User>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * すべてのユーザ一覧取得
     * @param {module:api/PropallyAgentV1Api~agentV1UsersGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/User>}
     */
    agentV1UsersGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [User];
      return this.apiClient.callApi(
        '/agent/v1/users', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1UsersSearchPost operation.
     * @callback module:api/PropallyAgentV1Api~agentV1UsersSearchPostCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/User>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * ユーザ検索
     * @param {Object} opts Optional parameters
     * @param {module:model/UserSearchRequest} [userSearchRequest] 
     * @param {module:api/PropallyAgentV1Api~agentV1UsersSearchPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/User>}
     */
    agentV1UsersSearchPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['userSearchRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [User];
      return this.apiClient.callApi(
        '/agent/v1/users/search', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1UsersUserIdAssessmentProposalsGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1UsersUserIdAssessmentProposalsGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AssessmentProposal>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 対象ユーザ査定提案済み一覧
     * @param {Number} userId ユーザ ID
     * @param {module:api/PropallyAgentV1Api~agentV1UsersUserIdAssessmentProposalsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AssessmentProposal>}
     */
    agentV1UsersUserIdAssessmentProposalsGet(userId, callback) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling agentV1UsersUserIdAssessmentProposalsGet");
      }

      let pathParams = {
        'user_id': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [AssessmentProposal];
      return this.apiClient.callApi(
        '/agent/v1/users/{user_id}/assessment_proposals', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1UsersUserIdFavoriteDelete operation.
     * @callback module:api/PropallyAgentV1Api~agentV1UsersUserIdFavoriteDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * お気に入りユーザ解除
     * @param {Number} userId ユーザ ID
     * @param {module:api/PropallyAgentV1Api~agentV1UsersUserIdFavoriteDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1UsersUserIdFavoriteDelete(userId, callback) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling agentV1UsersUserIdFavoriteDelete");
      }

      let pathParams = {
        'user_id': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/users/{user_id}/favorite', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1UsersUserIdFavoritePatch operation.
     * @callback module:api/PropallyAgentV1Api~agentV1UsersUserIdFavoritePatchCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ResponseMessage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * お気に入りユーザ登録
     * @param {Number} userId ユーザ ID
     * @param {module:api/PropallyAgentV1Api~agentV1UsersUserIdFavoritePatchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ResponseMessage}
     */
    agentV1UsersUserIdFavoritePatch(userId, callback) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling agentV1UsersUserIdFavoritePatch");
      }

      let pathParams = {
        'user_id': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ResponseMessage;
      return this.apiClient.callApi(
        '/agent/v1/users/{user_id}/favorite', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1UsersUserIdGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1UsersUserIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/User} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * ユーザ情報1件取得
     * @param {Number} userId ユーザ ID
     * @param {module:api/PropallyAgentV1Api~agentV1UsersUserIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/User}
     */
    agentV1UsersUserIdGet(userId, callback) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling agentV1UsersUserIdGet");
      }

      let pathParams = {
        'user_id': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/agent/v1/users/{user_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1UsersUserIdInvestmentProposalsGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1UsersUserIdInvestmentProposalsGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/InvestmentProposal>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 対象ユーザ投資提案済み一覧
     * @param {Number} userId ユーザ ID
     * @param {module:api/PropallyAgentV1Api~agentV1UsersUserIdInvestmentProposalsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/InvestmentProposal>}
     */
    agentV1UsersUserIdInvestmentProposalsGet(userId, callback) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling agentV1UsersUserIdInvestmentProposalsGet");
      }

      let pathParams = {
        'user_id': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [InvestmentProposal];
      return this.apiClient.callApi(
        '/agent/v1/users/{user_id}/investment_proposals', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1UsersUserIdMemoGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1UsersUserIdMemoGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AgentUserMemo} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 対象エージェントのユーザメモ取得
     * @param {Number} userId ユーザ ID
     * @param {module:api/PropallyAgentV1Api~agentV1UsersUserIdMemoGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AgentUserMemo}
     */
    agentV1UsersUserIdMemoGet(userId, callback) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling agentV1UsersUserIdMemoGet");
      }

      let pathParams = {
        'user_id': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = AgentUserMemo;
      return this.apiClient.callApi(
        '/agent/v1/users/{user_id}/memo', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1UsersUserIdMemoPatch operation.
     * @callback module:api/PropallyAgentV1Api~agentV1UsersUserIdMemoPatchCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AgentUserMemo} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 対象エージェントのユーザメモ登録 or 更新
     * @param {Number} userId ユーザ ID
     * @param {Object} opts Optional parameters
     * @param {module:model/AgentUserMemo} [agentUserMemo] 
     * @param {module:api/PropallyAgentV1Api~agentV1UsersUserIdMemoPatchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AgentUserMemo}
     */
    agentV1UsersUserIdMemoPatch(userId, opts, callback) {
      opts = opts || {};
      let postBody = opts['agentUserMemo'];
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling agentV1UsersUserIdMemoPatch");
      }

      let pathParams = {
        'user_id': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = AgentUserMemo;
      return this.apiClient.callApi(
        '/agent/v1/users/{user_id}/memo', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1UsersUserIdPropertiesGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1UsersUserIdPropertiesGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Property>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * ユーザの物件一覧取得
     * @param {Number} userId ユーザ ID
     * @param {module:api/PropallyAgentV1Api~agentV1UsersUserIdPropertiesGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Property>}
     */
    agentV1UsersUserIdPropertiesGet(userId, callback) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling agentV1UsersUserIdPropertiesGet");
      }

      let pathParams = {
        'user_id': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Property];
      return this.apiClient.callApi(
        '/agent/v1/users/{user_id}/properties', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the agentV1UsersUserIdProposalMatchingsGet operation.
     * @callback module:api/PropallyAgentV1Api~agentV1UsersUserIdProposalMatchingsGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ProposalMatching>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 対象ユーザマッチ済み一覧
     * @param {Number} userId ユーザ ID
     * @param {module:api/PropallyAgentV1Api~agentV1UsersUserIdProposalMatchingsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ProposalMatching>}
     */
    agentV1UsersUserIdProposalMatchingsGet(userId, callback) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling agentV1UsersUserIdProposalMatchingsGet");
      }

      let pathParams = {
        'user_id': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProposalMatching];
      return this.apiClient.callApi(
        '/agent/v1/users/{user_id}/proposal_matchings', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
