/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import IntegerRange from './IntegerRange';
import JobCategory from './JobCategory';
import SearchRange from './SearchRange';

/**
 * The UserSearchRequest model module.
 * @module model/UserSearchRequest
 * @version 1.0.0
 */
class UserSearchRequest {
    /**
     * Constructs a new <code>UserSearchRequest</code>.
     * @alias module:model/UserSearchRequest
     */
    constructor() { 
        
        UserSearchRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>UserSearchRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserSearchRequest} obj Optional instance to populate.
     * @return {module:model/UserSearchRequest} The populated <code>UserSearchRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserSearchRequest();

            if (data.hasOwnProperty('age')) {
                obj['age'] = IntegerRange.constructFromObject(data['age']);
            }
            if (data.hasOwnProperty('annual_income')) {
                obj['annual_income'] = IntegerRange.constructFromObject(data['annual_income']);
            }
            if (data.hasOwnProperty('job_category')) {
                obj['job_category'] = JobCategory.constructFromObject(data['job_category']);
            }
            if (data.hasOwnProperty('job_continuous_year')) {
                obj['job_continuous_year'] = ApiClient.convertToType(data['job_continuous_year'], 'Number');
            }
            if (data.hasOwnProperty('interest_tag_ids')) {
                obj['interest_tag_ids'] = ApiClient.convertToType(data['interest_tag_ids'], ['Number']);
            }
            if (data.hasOwnProperty('extract_number')) {
                obj['extract_number'] = SearchRange.constructFromObject(data['extract_number']);
            }
            if (data.hasOwnProperty('is_favorite')) {
                obj['is_favorite'] = ApiClient.convertToType(data['is_favorite'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UserSearchRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserSearchRequest</code>.
     */
    static validateJSON(data) {
        // validate the optional field `age`
        if (data['age']) { // data not null
          IntegerRange.validateJSON(data['age']);
        }
        // validate the optional field `annual_income`
        if (data['annual_income']) { // data not null
          IntegerRange.validateJSON(data['annual_income']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['interest_tag_ids'])) {
            throw new Error("Expected the field `interest_tag_ids` to be an array in the JSON data but got " + data['interest_tag_ids']);
        }
        // validate the optional field `extract_number`
        if (data['extract_number']) { // data not null
          SearchRange.validateJSON(data['extract_number']);
        }

        return true;
    }


}



/**
 * @member {module:model/IntegerRange} age
 */
UserSearchRequest.prototype['age'] = undefined;

/**
 * @member {module:model/IntegerRange} annual_income
 */
UserSearchRequest.prototype['annual_income'] = undefined;

/**
 * @member {module:model/JobCategory} job_category
 */
UserSearchRequest.prototype['job_category'] = undefined;

/**
 * 勤続年数
 * @member {Number} job_continuous_year
 */
UserSearchRequest.prototype['job_continuous_year'] = undefined;

/**
 * @member {Array.<Number>} interest_tag_ids
 */
UserSearchRequest.prototype['interest_tag_ids'] = undefined;

/**
 * @member {module:model/SearchRange} extract_number
 */
UserSearchRequest.prototype['extract_number'] = undefined;

/**
 * @member {Boolean} is_favorite
 */
UserSearchRequest.prototype['is_favorite'] = undefined;






export default UserSearchRequest;

