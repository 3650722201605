import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { DoingOverlay } from "./components/Organisms/DoingOverlay";
import { AuthProvider } from "./providers/AuthProvider";
import { UnreadMatchingProvider } from "./providers/UnreadMatchingProvider";
import { routerPaths } from "./routes";

import { theme } from "./theme";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <RecoilRoot>
          <BrowserRouter>
            <AuthProvider>
              <UnreadMatchingProvider>
                <Suspense fallback={<DoingOverlay open={true} />}>
                  <Routes>
                    {/* <Route index element={<Home />} /> */}
                    {routerPaths.map((routerPath) => (
                      <Route key={routerPath.path} path={routerPath.path} element={routerPath.element}>
                        {routerPath.children?.map((child) => (
                          <Route key={child.path} path={child.path} element={child.element} />
                        ))}
                      </Route>
                    ))}
                  </Routes>
                </Suspense>
              </UnreadMatchingProvider>
            </AuthProvider>
          </BrowserRouter>
        </RecoilRoot>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
