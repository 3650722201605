/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MonthlyCashflowResult model module.
 * @module model/MonthlyCashflowResult
 * @version 1.0.0
 */
class MonthlyCashflowResult {
    /**
     * Constructs a new <code>MonthlyCashflowResult</code>.
     * @alias module:model/MonthlyCashflowResult
     */
    constructor() { 
        
        MonthlyCashflowResult.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MonthlyCashflowResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MonthlyCashflowResult} obj Optional instance to populate.
     * @return {module:model/MonthlyCashflowResult} The populated <code>MonthlyCashflowResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MonthlyCashflowResult();

            if (data.hasOwnProperty('property_id')) {
                obj['property_id'] = ApiClient.convertToType(data['property_id'], 'Number');
            }
            if (data.hasOwnProperty('month')) {
                obj['month'] = ApiClient.convertToType(data['month'], 'Date');
            }
            if (data.hasOwnProperty('rent_income')) {
                obj['rent_income'] = ApiClient.convertToType(data['rent_income'], 'Number');
            }
            if (data.hasOwnProperty('management_fee_income')) {
                obj['management_fee_income'] = ApiClient.convertToType(data['management_fee_income'], 'Number');
            }
            if (data.hasOwnProperty('management_cost')) {
                obj['management_cost'] = ApiClient.convertToType(data['management_cost'], 'Number');
            }
            if (data.hasOwnProperty('building_management_cost')) {
                obj['building_management_cost'] = ApiClient.convertToType(data['building_management_cost'], 'Number');
            }
            if (data.hasOwnProperty('repair_fund_cost')) {
                obj['repair_fund_cost'] = ApiClient.convertToType(data['repair_fund_cost'], 'Number');
            }
            if (data.hasOwnProperty('misc_cost')) {
                obj['misc_cost'] = ApiClient.convertToType(data['misc_cost'], 'Number');
            }
            if (data.hasOwnProperty('loan_interest')) {
                obj['loan_interest'] = ApiClient.convertToType(data['loan_interest'], 'Number');
            }
            if (data.hasOwnProperty('loan_interest_rate_percentage')) {
                obj['loan_interest_rate_percentage'] = ApiClient.convertToType(data['loan_interest_rate_percentage'], 'Number');
            }
            if (data.hasOwnProperty('loan_principal')) {
                obj['loan_principal'] = ApiClient.convertToType(data['loan_principal'], 'Number');
            }
            if (data.hasOwnProperty('loan_prepayment')) {
                obj['loan_prepayment'] = ApiClient.convertToType(data['loan_prepayment'], 'Number');
            }
            if (data.hasOwnProperty('loan_prepayment_method')) {
                obj['loan_prepayment_method'] = ApiClient.convertToType(data['loan_prepayment_method'], 'String');
            }
            if (data.hasOwnProperty('key_money')) {
                obj['key_money'] = ApiClient.convertToType(data['key_money'], 'Number');
            }
            if (data.hasOwnProperty('security_deposit')) {
                obj['security_deposit'] = ApiClient.convertToType(data['security_deposit'], 'Number');
            }
            if (data.hasOwnProperty('property_tax')) {
                obj['property_tax'] = ApiClient.convertToType(data['property_tax'], 'Number');
            }
            if (data.hasOwnProperty('fire_insurance_cost')) {
                obj['fire_insurance_cost'] = ApiClient.convertToType(data['fire_insurance_cost'], 'Number');
            }
            if (data.hasOwnProperty('fire_insurance_holding_period')) {
                obj['fire_insurance_holding_period'] = ApiClient.convertToType(data['fire_insurance_holding_period'], 'Number');
            }
            if (data.hasOwnProperty('earthquake_insurance_cost')) {
                obj['earthquake_insurance_cost'] = ApiClient.convertToType(data['earthquake_insurance_cost'], 'Number');
            }
            if (data.hasOwnProperty('earthquake_insurance_holding_period')) {
                obj['earthquake_insurance_holding_period'] = ApiClient.convertToType(data['earthquake_insurance_holding_period'], 'Number');
            }
            if (data.hasOwnProperty('large_scale_repair_fund_cost')) {
                obj['large_scale_repair_fund_cost'] = ApiClient.convertToType(data['large_scale_repair_fund_cost'], 'Number');
            }
            if (data.hasOwnProperty('misc_onetime_cost')) {
                obj['misc_onetime_cost'] = ApiClient.convertToType(data['misc_onetime_cost'], 'Number');
            }
            if (data.hasOwnProperty('misc_onetime_cost_contents')) {
                obj['misc_onetime_cost_contents'] = ApiClient.convertToType(data['misc_onetime_cost_contents'], 'String');
            }
            if (data.hasOwnProperty('tax_on_real_estates')) {
                obj['tax_on_real_estates'] = ApiClient.convertToType(data['tax_on_real_estates'], 'Number');
            }
            if (data.hasOwnProperty('outsourcing_fee_of_new_lease_contract')) {
                obj['outsourcing_fee_of_new_lease_contract'] = ApiClient.convertToType(data['outsourcing_fee_of_new_lease_contract'], 'Number');
            }
            if (data.hasOwnProperty('outsourcing_fee_of_updating_lease_contract')) {
                obj['outsourcing_fee_of_updating_lease_contract'] = ApiClient.convertToType(data['outsourcing_fee_of_updating_lease_contract'], 'Number');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('discarded_at')) {
                obj['discarded_at'] = ApiClient.convertToType(data['discarded_at'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MonthlyCashflowResult</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MonthlyCashflowResult</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['loan_prepayment_method'] && !(typeof data['loan_prepayment_method'] === 'string' || data['loan_prepayment_method'] instanceof String)) {
            throw new Error("Expected the field `loan_prepayment_method` to be a primitive type in the JSON string but got " + data['loan_prepayment_method']);
        }
        // ensure the json data is a string
        if (data['misc_onetime_cost_contents'] && !(typeof data['misc_onetime_cost_contents'] === 'string' || data['misc_onetime_cost_contents'] instanceof String)) {
            throw new Error("Expected the field `misc_onetime_cost_contents` to be a primitive type in the JSON string but got " + data['misc_onetime_cost_contents']);
        }

        return true;
    }


}



/**
 * @member {Number} property_id
 */
MonthlyCashflowResult.prototype['property_id'] = undefined;

/**
 * @member {Date} month
 */
MonthlyCashflowResult.prototype['month'] = undefined;

/**
 * 賃料収入
 * @member {Number} rent_income
 */
MonthlyCashflowResult.prototype['rent_income'] = undefined;

/**
 * 管理費収入
 * @member {Number} management_fee_income
 */
MonthlyCashflowResult.prototype['management_fee_income'] = undefined;

/**
 * 賃貸管理費
 * @member {Number} management_cost
 */
MonthlyCashflowResult.prototype['management_cost'] = undefined;

/**
 * 物件管理費
 * @member {Number} building_management_cost
 */
MonthlyCashflowResult.prototype['building_management_cost'] = undefined;

/**
 * 修繕積立金
 * @member {Number} repair_fund_cost
 */
MonthlyCashflowResult.prototype['repair_fund_cost'] = undefined;

/**
 * その他経費（毎月発生するもの)
 * @member {Number} misc_cost
 */
MonthlyCashflowResult.prototype['misc_cost'] = undefined;

/**
 * ローン支払い（金利分）
 * @member {Number} loan_interest
 */
MonthlyCashflowResult.prototype['loan_interest'] = undefined;

/**
 * ローン利率（当月からの）（％）
 * @member {Number} loan_interest_rate_percentage
 */
MonthlyCashflowResult.prototype['loan_interest_rate_percentage'] = undefined;

/**
 * ローン支払い（元金分）
 * @member {Number} loan_principal
 */
MonthlyCashflowResult.prototype['loan_principal'] = undefined;

/**
 * ローン支払い（元金に対する繰上返済）
 * @member {Number} loan_prepayment
 */
MonthlyCashflowResult.prototype['loan_prepayment'] = undefined;

/**
 * ローン支払い（元金に対する繰上返済方式） * ReduceLoanAmount: 返済額軽減型 * ReduceLoanPaymentPeriod: 期間短縮型 
 * @member {module:model/MonthlyCashflowResult.LoanPrepaymentMethodEnum} loan_prepayment_method
 */
MonthlyCashflowResult.prototype['loan_prepayment_method'] = undefined;

/**
 * 礼金
 * @member {Number} key_money
 */
MonthlyCashflowResult.prototype['key_money'] = undefined;

/**
 * 更新料
 * @member {Number} security_deposit
 */
MonthlyCashflowResult.prototype['security_deposit'] = undefined;

/**
 * 固定資産税
 * @member {Number} property_tax
 */
MonthlyCashflowResult.prototype['property_tax'] = undefined;

/**
 * 火災保険料
 * @member {Number} fire_insurance_cost
 */
MonthlyCashflowResult.prototype['fire_insurance_cost'] = undefined;

/**
 * 火災保険料保有期間
 * @member {Number} fire_insurance_holding_period
 */
MonthlyCashflowResult.prototype['fire_insurance_holding_period'] = undefined;

/**
 * 地震保険料
 * @member {Number} earthquake_insurance_cost
 */
MonthlyCashflowResult.prototype['earthquake_insurance_cost'] = undefined;

/**
 * 地震保険料保有期間
 * @member {Number} earthquake_insurance_holding_period
 */
MonthlyCashflowResult.prototype['earthquake_insurance_holding_period'] = undefined;

/**
 * 大規模修繕費用
 * @member {Number} large_scale_repair_fund_cost
 */
MonthlyCashflowResult.prototype['large_scale_repair_fund_cost'] = undefined;

/**
 * その他一過性の費用
 * @member {Number} misc_onetime_cost
 */
MonthlyCashflowResult.prototype['misc_onetime_cost'] = undefined;

/**
 * その他一過性の費用(内容)
 * @member {String} misc_onetime_cost_contents
 */
MonthlyCashflowResult.prototype['misc_onetime_cost_contents'] = undefined;

/**
 * 不動産所得にかかる税金
 * @member {Number} tax_on_real_estates
 */
MonthlyCashflowResult.prototype['tax_on_real_estates'] = undefined;

/**
 * 新規賃貸契約手数料（金額）
 * @member {Number} outsourcing_fee_of_new_lease_contract
 */
MonthlyCashflowResult.prototype['outsourcing_fee_of_new_lease_contract'] = undefined;

/**
 * 賃貸契約更新手数料（金額）
 * @member {Number} outsourcing_fee_of_updating_lease_contract
 */
MonthlyCashflowResult.prototype['outsourcing_fee_of_updating_lease_contract'] = undefined;

/**
 * @member {Date} created_at
 */
MonthlyCashflowResult.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
MonthlyCashflowResult.prototype['updated_at'] = undefined;

/**
 * @member {Date} discarded_at
 */
MonthlyCashflowResult.prototype['discarded_at'] = undefined;





/**
 * Allowed values for the <code>loan_prepayment_method</code> property.
 * @enum {String}
 * @readonly
 */
MonthlyCashflowResult['LoanPrepaymentMethodEnum'] = {

    /**
     * value: "ReduceLoanAmount"
     * @const
     */
    "ReduceLoanAmount": "ReduceLoanAmount",

    /**
     * value: "ReduceLoanPaymentPeriod"
     * @const
     */
    "ReduceLoanPaymentPeriod": "ReduceLoanPaymentPeriod"
};



export default MonthlyCashflowResult;

