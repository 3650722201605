import dayjs from "dayjs";
import { z } from "zod";

export const propertySearchFormSchema = z
  .object({
    prefectures: z.string().array(),
    cities: z.string().array(),
    minBuiltAt: z.string(),
    maxBuiltAt: z.string(),
    minRentIncome: z.number().or(z.enum(["undefined"])),
    maxRentIncome: z.number().or(z.enum(["undefined"])),
    managementType: z.string(),
    minOccupiedArea: z.number().or(z.enum(["undefined"])),
    maxOccupiedArea: z.number().or(z.enum(["undefined"])),
    walk: z.number().or(z.enum(["undefined"])),
    yearsPassedFromBoughtAt: z.number(),
  })
  .refine(
    (args) => {
      const { minBuiltAt, maxBuiltAt } = args;
      if (minBuiltAt === "undefined" || maxBuiltAt === "undefined") return true;
      return dayjs(minBuiltAt).isBefore(dayjs(maxBuiltAt));
    },
    {
      message: "範囲が逆転しています",
      path: ["minBuiltAt"],
    }
  )
  .refine(
    (args) => {
      const { minBuiltAt, maxBuiltAt } = args;
      if (minBuiltAt === "undefined" || maxBuiltAt === "undefined") return true;
      return dayjs(minBuiltAt).isBefore(dayjs(maxBuiltAt));
    },
    {
      message: "範囲が逆転しています",
      path: ["maxBuiltAt"],
    }
  )
  .refine(
    (args) => {
      const { minRentIncome, maxRentIncome } = args;
      if (minRentIncome === "undefined" || maxRentIncome === "undefined") return true;
      return minRentIncome <= maxRentIncome;
    },
    {
      message: "範囲が逆転しています",
      path: ["minRentIncome"],
    }
  )
  .refine(
    (args) => {
      const { minRentIncome, maxRentIncome } = args;
      if (minRentIncome === "undefined" || maxRentIncome === "undefined") return true;
      return minRentIncome <= maxRentIncome;
    },
    {
      message: "範囲が逆転しています",
      path: ["maxRentIncome"],
    }
  )
  .refine(
    (args) => {
      const { minOccupiedArea, maxOccupiedArea } = args;
      if (minOccupiedArea === "undefined" || maxOccupiedArea === "undefined") return true;
      return minOccupiedArea <= maxOccupiedArea;
    },
    {
      message: "範囲が逆転しています",
      path: ["minOccupiedArea"],
    }
  )
  .refine(
    (args) => {
      const { minOccupiedArea, maxOccupiedArea } = args;
      if (minOccupiedArea === "undefined" || maxOccupiedArea === "undefined") return true;
      return minOccupiedArea <= maxOccupiedArea;
    },
    {
      message: "範囲が逆転しています",
      path: ["maxOccupiedArea"],
    }
  );
export type PropertySearchFormSchema = z.infer<typeof propertySearchFormSchema>;

type RangeOption = Partial<{
  min: number;
  max: number;
}>;

export type propertySearchRequestType = Partial<{
  prefectures: prefecturesRequestType[];
  built_at: Partial<{
    min: string;
    max: string;
  }>;
  monthly_rent_income: RangeOption;
  management_type: string;
  occupied_area_m2_min: RangeOption;
  walk: RangeOption;
  extract_number: RangeOption;
  is_favorite: boolean;
  years_passed_from_bought_at: number;
}>;

export type prefecturesRequestType = {
  name: string;
  children?: prefecturesRequestType[];
};

export const defaultPropertySearchFormSchema: PropertySearchFormSchema = {
  prefectures: [],
  cities: [],
  minBuiltAt: "undefined",
  maxBuiltAt: "undefined",
  minRentIncome: "undefined",
  maxRentIncome: "undefined",
  managementType: "undefined",
  minOccupiedArea: "undefined",
  maxOccupiedArea: "undefined",
  walk: 0,
  yearsPassedFromBoughtAt: 0,
}
