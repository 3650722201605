import CheckIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import MUIDialog from "@mui/material/Modal";
import * as React from "react";
import { Colors } from "../../constants/Colors";
import Button from "../Atoms/Button";

interface DialogProps {
  /**
   * A function that is executed when the button is clicked.
   */
  onClickButton: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined;
  /**
   * 見える｜見えない
   */
  isDisplayed: boolean;
  /**
   * Modalの中のテキスト
   * */
  insideText: string;
  /**
   * Button1の中のテキスト
   * */
  ButtonLabel: string;
  /**
   * [Optional] A flag indicating whether the button is disabled.
   * If set to true, the button is disabled.
   */
  isDisabled?: boolean;
}

const dialogStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 224,
  background: Colors.White,
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  flexDirection: "column",
  alignContent: "center",
  flexWrap: "wrap",
  padding: "32px 38px",
};

const textStyle: React.CSSProperties = {
  fontFamily: "Hiragino Sans",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: 13,
  lineHeight: "20px",
  textAlign: "center",
  letterSpacing: "0.1em",
  color: "#424750",
  paddingTop: 22,
  paddingBottom: 22,
  whiteSpace: "pre-line",
};

export const ConfirmDialog = (props: DialogProps) => {
  return (
    <MUIDialog open={props.isDisplayed} aria-describedby="dialog--description">
      <Box sx={dialogStyle}>
        <CheckIcon style={{ color: "#FF6F00", fontSize: "50.4px" }} />
        <span style={textStyle}>{props.insideText}</span>
        <Button size="large" label={props.ButtonLabel} onClick={props.onClickButton} />
      </Box>
    </MUIDialog>
  );
};
