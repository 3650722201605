/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Agent from './Agent';
import User from './User';

/**
 * The ProposalMatching model module.
 * @module model/ProposalMatching
 * @version 1.0.0
 */
class ProposalMatching {
    /**
     * Constructs a new <code>ProposalMatching</code>.
     * @alias module:model/ProposalMatching
     */
    constructor() { 
        
        ProposalMatching.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProposalMatching</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProposalMatching} obj Optional instance to populate.
     * @return {module:model/ProposalMatching} The populated <code>ProposalMatching</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProposalMatching();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('agent_id')) {
                obj['agent_id'] = ApiClient.convertToType(data['agent_id'], 'Number');
            }
            if (data.hasOwnProperty('user_id')) {
                obj['user_id'] = ApiClient.convertToType(data['user_id'], 'Number');
            }
            if (data.hasOwnProperty('assessment_proposal_id')) {
                obj['assessment_proposal_id'] = ApiClient.convertToType(data['assessment_proposal_id'], 'Number');
            }
            if (data.hasOwnProperty('investment_proposal_id')) {
                obj['investment_proposal_id'] = ApiClient.convertToType(data['investment_proposal_id'], 'Number');
            }
            if (data.hasOwnProperty('is_agent_read')) {
                obj['is_agent_read'] = ApiClient.convertToType(data['is_agent_read'], 'Boolean');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('agent')) {
                obj['agent'] = Agent.constructFromObject(data['agent']);
            }
            if (data.hasOwnProperty('user')) {
                obj['user'] = User.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('is_contract_applied')) {
                obj['is_contract_applied'] = ApiClient.convertToType(data['is_contract_applied'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProposalMatching</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProposalMatching</code>.
     */
    static validateJSON(data) {
        // validate the optional field `agent`
        if (data['agent']) { // data not null
          Agent.validateJSON(data['agent']);
        }
        // validate the optional field `user`
        if (data['user']) { // data not null
          User.validateJSON(data['user']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
ProposalMatching.prototype['id'] = undefined;

/**
 * @member {Number} agent_id
 */
ProposalMatching.prototype['agent_id'] = undefined;

/**
 * @member {Number} user_id
 */
ProposalMatching.prototype['user_id'] = undefined;

/**
 * @member {Number} assessment_proposal_id
 */
ProposalMatching.prototype['assessment_proposal_id'] = undefined;

/**
 * @member {Number} investment_proposal_id
 */
ProposalMatching.prototype['investment_proposal_id'] = undefined;

/**
 * @member {Boolean} is_agent_read
 */
ProposalMatching.prototype['is_agent_read'] = undefined;

/**
 * @member {Date} created_at
 */
ProposalMatching.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ProposalMatching.prototype['updated_at'] = undefined;

/**
 * @member {module:model/Agent} agent
 */
ProposalMatching.prototype['agent'] = undefined;

/**
 * @member {module:model/User} user
 */
ProposalMatching.prototype['user'] = undefined;

/**
 * 対象ユーザの成約申請済みが1件以上あるかどうか
 * @member {Boolean} is_contract_applied
 */
ProposalMatching.prototype['is_contract_applied'] = undefined;






export default ProposalMatching;

