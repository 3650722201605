export const OccupationListReadonly = [
  "会社員（上場）",
  "会社員（非上場）",
  "会社員（外資系企業）",
  "会社役員",
  "公務員",
  "士業",
  "医師・歯科医師",
  "薬剤師・看護師",
  "自営業",
  "その他",
] as const;

export type OccupationsType = (typeof OccupationListReadonly)[number];

export const Occupations: OccupationsType[] = [
  "会社員（上場）",
  "会社員（非上場）",
  "会社員（外資系企業）",
  "会社役員",
  "公務員",
  "士業",
  "医師・歯科医師",
  "薬剤師・看護師",
  "自営業",
  "その他",
];
