import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

export const DoingOverlay: React.FC<{ open: boolean; text?: string }> = ({ open, text }) => (
  <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
    <Typography variant="h5">{text}</Typography>
    <CircularProgress color="inherit" />
  </Backdrop>
);
