/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class JobIndustry.
* @enum {}
* @readonly
*/
export default class JobIndustry {
    
        /**
         * value: "IT・通信系"
         * @const
         */
        "IT・通信系" = "IT・通信系";

    
        /**
         * value: "サービス系"
         * @const
         */
        "サービス系" = "サービス系";

    
        /**
         * value: "メーカー系(電気・機械)"
         * @const
         */
        "メーカー系(電気・機械)" = "メーカー系(電気・機械)";

    
        /**
         * value: "メーカー系(素材・医薬品他)"
         * @const
         */
        "メーカー系(素材・医薬品他)" = "メーカー系(素材・医薬品他)";

    
        /**
         * value: "専門コンサル系"
         * @const
         */
        "専門コンサル系" = "専門コンサル系";

    
        /**
         * value: "商社系(電気・機械系)"
         * @const
         */
        "商社系(電気・機械系)" = "商社系(電気・機械系)";

    
        /**
         * value: "商社系(総合・素材・医薬品他)"
         * @const
         */
        "商社系(総合・素材・医薬品他)" = "商社系(総合・素材・医薬品他)";

    
        /**
         * value: "金融・保険系"
         * @const
         */
        "金融・保険系" = "金融・保険系";

    
        /**
         * value: "不動産系"
         * @const
         */
        "不動産系" = "不動産系";

    
        /**
         * value: "建設系"
         * @const
         */
        "建設系" = "建設系";

    
        /**
         * value: "マスコミ系"
         * @const
         */
        "マスコミ系" = "マスコミ系";

    
        /**
         * value: "流通・小売系"
         * @const
         */
        "流通・小売系" = "流通・小売系";

    

    /**
    * Returns a <code>JobIndustry</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/JobIndustry} The enum <code>JobIndustry</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

