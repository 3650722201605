import { atom } from "recoil";
import { recoilPersist as recoilPersistLocalStorage , recoilPersist as recoilPersistSessionStorage } from "recoil-persist";

import { Agent, Company, AgentProfileImage, CompanyLogoImage } from "../api/generated/src";
import {
  defaultPropertySearchFormSchema,
  PropertySearchFormSchema,
} from "../types/PropertySearch";
import {defaultUserSearchFormType, SearchFormType} from "../types/UsersSearch";

const { persistAtom: persistAtomLocalStorage } = recoilPersistLocalStorage({
  key: "recoil-persist-local", // ローカルストレージ用のキー
  storage: localStorage, // ローカルストレージを使用
});

const { persistAtom: persistAtomSessionStorage } = recoilPersistSessionStorage({
  key: "recoil-persist-session", // セッションストレージ用のキー
  storage: sessionStorage, // セッションストレージを使用
});

export type LoggedIn = {
  logged_in: boolean;
};
export const LoggedInState = atom<LoggedIn>({
  key: "LoggedInState",
  default: {
    logged_in: false,
  },
  // 永続化する
  effects_UNSTABLE: [persistAtomLocalStorage],
});

export const AgentState = atom<Agent>({
  key: "AgentState",
  default: {
    id: 0,
    profile: "",
    first_name: "",
    family_name: "unknown",
    is_enabled_notification_new_user: true,
    is_enabled_notification_new_property: true,
    agent_profile_image: {
      image_url: "/user.svg",
    } as AgentProfileImage,
  } as Agent,
  // 永続化する
  effects_UNSTABLE: [persistAtomLocalStorage],
});

export const CompanyState = atom<Company>({
  key: "CompanyState",
  default: {
    name: "未入力",
    home_page_url: "",
    achievements: "",
    strengths_of_properties_for_sale: "",
    strengths_of_properties_for_purchase: "",
    strengths_of_partner_financial_institutions: "",
    strengths_of_properties_for_management: "",
    advice_except_real_estate: "",
    partner: "",
    appeal_point: "",
    company_speciality_tag_ids: [],
    company_logo_image: {
      image_url: "/user.svg",
    } as CompanyLogoImage,
  } as Company,
  // 永続化する
  effects_UNSTABLE: [persistAtomLocalStorage],
});

export const SearchFormTypeForUserListState = atom<SearchFormType>({
  key: "SearchFormTypeForUserListState",
  default: {
    ...defaultUserSearchFormType,
  },
  // 検索条件保存はブラウザ閉じたらリセットしたい要件のためセッションストレージに保存
  effects_UNSTABLE: [persistAtomSessionStorage],
});

export const SearchFormSchemaForPropertyListState = atom<PropertySearchFormSchema>({
  key: "SearchFormSchemaForPropertyListState",
  default: {
    ...defaultPropertySearchFormSchema,
  },
  // 検索条件保存はブラウザ閉じたらリセットしたい要件のためセッションストレージに保存
  effects_UNSTABLE: [persistAtomSessionStorage],
});
