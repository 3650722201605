/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Agent from './Agent';
import AssessmentProposal from './AssessmentProposal';
import AssessmentProposalAttachmentFile from './AssessmentProposalAttachmentFile';
import Property from './Property';
import ProposalStatusEnum from './ProposalStatusEnum';

/**
 * The AssessmentProposalRequest model module.
 * @module model/AssessmentProposalRequest
 * @version 1.0.0
 */
class AssessmentProposalRequest {
    /**
     * Constructs a new <code>AssessmentProposalRequest</code>.
     * @alias module:model/AssessmentProposalRequest
     * @implements module:model/AssessmentProposal
     * @param proposalPrice {Number} 
     * @param proposalContent {String} 
     * @param tradeType {Number} 
     */
    constructor(proposalPrice, proposalContent, tradeType) { 
        AssessmentProposal.initialize(this);
        AssessmentProposalRequest.initialize(this, proposalPrice, proposalContent, tradeType);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, proposalPrice, proposalContent, tradeType) { 
        obj['proposal_price'] = proposalPrice;
        obj['proposal_content'] = proposalContent;
        obj['trade_type'] = tradeType;
    }

    /**
     * Constructs a <code>AssessmentProposalRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AssessmentProposalRequest} obj Optional instance to populate.
     * @return {module:model/AssessmentProposalRequest} The populated <code>AssessmentProposalRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AssessmentProposalRequest();
            AssessmentProposal.constructFromObject(data, obj);

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('agent_id')) {
                obj['agent_id'] = ApiClient.convertToType(data['agent_id'], 'Number');
            }
            if (data.hasOwnProperty('property_id')) {
                obj['property_id'] = ApiClient.convertToType(data['property_id'], 'Number');
            }
            if (data.hasOwnProperty('user_id')) {
                obj['user_id'] = ApiClient.convertToType(data['user_id'], 'Number');
            }
            if (data.hasOwnProperty('proposal_price')) {
                obj['proposal_price'] = ApiClient.convertToType(data['proposal_price'], 'Number');
            }
            if (data.hasOwnProperty('proposal_content')) {
                obj['proposal_content'] = ApiClient.convertToType(data['proposal_content'], 'String');
            }
            if (data.hasOwnProperty('is_user_read')) {
                obj['is_user_read'] = ApiClient.convertToType(data['is_user_read'], 'Boolean');
            }
            if (data.hasOwnProperty('is_user_approved')) {
                obj['is_user_approved'] = ApiClient.convertToType(data['is_user_approved'], 'Boolean');
            }
            if (data.hasOwnProperty('trade_type')) {
                obj['trade_type'] = ApiClient.convertToType(data['trade_type'], 'Number');
            }
            if (data.hasOwnProperty('proposal_date')) {
                obj['proposal_date'] = ApiClient.convertToType(data['proposal_date'], 'Date');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('agent')) {
                obj['agent'] = Agent.constructFromObject(data['agent']);
            }
            if (data.hasOwnProperty('property')) {
                obj['property'] = Property.constructFromObject(data['property']);
            }
            if (data.hasOwnProperty('assessment_proposal_attachment_files')) {
                obj['assessment_proposal_attachment_files'] = ApiClient.convertToType(data['assessment_proposal_attachment_files'], [AssessmentProposalAttachmentFile]);
            }
            if (data.hasOwnProperty('proposal_status')) {
                obj['proposal_status'] = ProposalStatusEnum.constructFromObject(data['proposal_status']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AssessmentProposalRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AssessmentProposalRequest</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of AssessmentProposalRequest.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['proposal_content'] && !(typeof data['proposal_content'] === 'string' || data['proposal_content'] instanceof String)) {
            throw new Error("Expected the field `proposal_content` to be a primitive type in the JSON string but got " + data['proposal_content']);
        }
        // validate the optional field `agent`
        if (data['agent']) { // data not null
          Agent.validateJSON(data['agent']);
        }
        // validate the optional field `property`
        if (data['property']) { // data not null
          Property.validateJSON(data['property']);
        }
        if (data['assessment_proposal_attachment_files']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['assessment_proposal_attachment_files'])) {
                throw new Error("Expected the field `assessment_proposal_attachment_files` to be an array in the JSON data but got " + data['assessment_proposal_attachment_files']);
            }
            // validate the optional field `assessment_proposal_attachment_files` (array)
            for (const item of data['assessment_proposal_attachment_files']) {
                AssessmentProposalAttachmentFile.validateJSON(item);
            };
        }

        return true;
    }


}

AssessmentProposalRequest.RequiredProperties = ["proposal_price", "proposal_content", "trade_type"];

/**
 * @member {Number} id
 */
AssessmentProposalRequest.prototype['id'] = undefined;

/**
 * @member {Number} agent_id
 */
AssessmentProposalRequest.prototype['agent_id'] = undefined;

/**
 * @member {Number} property_id
 */
AssessmentProposalRequest.prototype['property_id'] = undefined;

/**
 * @member {Number} user_id
 */
AssessmentProposalRequest.prototype['user_id'] = undefined;

/**
 * @member {Number} proposal_price
 */
AssessmentProposalRequest.prototype['proposal_price'] = undefined;

/**
 * @member {String} proposal_content
 */
AssessmentProposalRequest.prototype['proposal_content'] = undefined;

/**
 * @member {Boolean} is_user_read
 */
AssessmentProposalRequest.prototype['is_user_read'] = undefined;

/**
 * @member {Boolean} is_user_approved
 */
AssessmentProposalRequest.prototype['is_user_approved'] = undefined;

/**
 * @member {Number} trade_type
 */
AssessmentProposalRequest.prototype['trade_type'] = undefined;

/**
 * @member {Date} proposal_date
 */
AssessmentProposalRequest.prototype['proposal_date'] = undefined;

/**
 * @member {Date} created_at
 */
AssessmentProposalRequest.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
AssessmentProposalRequest.prototype['updated_at'] = undefined;

/**
 * @member {module:model/Agent} agent
 */
AssessmentProposalRequest.prototype['agent'] = undefined;

/**
 * @member {module:model/Property} property
 */
AssessmentProposalRequest.prototype['property'] = undefined;

/**
 * @member {Array.<module:model/AssessmentProposalAttachmentFile>} assessment_proposal_attachment_files
 */
AssessmentProposalRequest.prototype['assessment_proposal_attachment_files'] = undefined;

/**
 * @member {module:model/ProposalStatusEnum} proposal_status
 */
AssessmentProposalRequest.prototype['proposal_status'] = undefined;


// Implement AssessmentProposal interface:
/**
 * @member {Number} id
 */
AssessmentProposal.prototype['id'] = undefined;
/**
 * @member {Number} agent_id
 */
AssessmentProposal.prototype['agent_id'] = undefined;
/**
 * @member {Number} property_id
 */
AssessmentProposal.prototype['property_id'] = undefined;
/**
 * @member {Number} user_id
 */
AssessmentProposal.prototype['user_id'] = undefined;
/**
 * @member {Number} proposal_price
 */
AssessmentProposal.prototype['proposal_price'] = undefined;
/**
 * @member {String} proposal_content
 */
AssessmentProposal.prototype['proposal_content'] = undefined;
/**
 * @member {Boolean} is_user_read
 */
AssessmentProposal.prototype['is_user_read'] = undefined;
/**
 * @member {Boolean} is_user_approved
 */
AssessmentProposal.prototype['is_user_approved'] = undefined;
/**
 * @member {Number} trade_type
 */
AssessmentProposal.prototype['trade_type'] = undefined;
/**
 * @member {Date} proposal_date
 */
AssessmentProposal.prototype['proposal_date'] = undefined;
/**
 * @member {Date} created_at
 */
AssessmentProposal.prototype['created_at'] = undefined;
/**
 * @member {Date} updated_at
 */
AssessmentProposal.prototype['updated_at'] = undefined;
/**
 * @member {module:model/Agent} agent
 */
AssessmentProposal.prototype['agent'] = undefined;
/**
 * @member {module:model/Property} property
 */
AssessmentProposal.prototype['property'] = undefined;
/**
 * @member {Array.<module:model/AssessmentProposalAttachmentFile>} assessment_proposal_attachment_files
 */
AssessmentProposal.prototype['assessment_proposal_attachment_files'] = undefined;
/**
 * @member {module:model/ProposalStatusEnum} proposal_status
 */
AssessmentProposal.prototype['proposal_status'] = undefined;




export default AssessmentProposalRequest;

