/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DateRange from './DateRange';
import IntegerRange from './IntegerRange';
import PropertySearchRequestPrefecturesInner from './PropertySearchRequestPrefecturesInner';
import SearchRange from './SearchRange';

/**
 * The PropertySearchRequest model module.
 * @module model/PropertySearchRequest
 * @version 1.0.0
 */
class PropertySearchRequest {
    /**
     * Constructs a new <code>PropertySearchRequest</code>.
     * @alias module:model/PropertySearchRequest
     */
    constructor() { 
        
        PropertySearchRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PropertySearchRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PropertySearchRequest} obj Optional instance to populate.
     * @return {module:model/PropertySearchRequest} The populated <code>PropertySearchRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PropertySearchRequest();

            if (data.hasOwnProperty('prefectures')) {
                obj['prefectures'] = ApiClient.convertToType(data['prefectures'], [PropertySearchRequestPrefecturesInner]);
            }
            if (data.hasOwnProperty('built_at')) {
                obj['built_at'] = DateRange.constructFromObject(data['built_at']);
            }
            if (data.hasOwnProperty('monthly_rent_income')) {
                obj['monthly_rent_income'] = IntegerRange.constructFromObject(data['monthly_rent_income']);
            }
            if (data.hasOwnProperty('management_type')) {
                obj['management_type'] = ApiClient.convertToType(data['management_type'], 'String');
            }
            if (data.hasOwnProperty('occupied_area_m2_min')) {
                obj['occupied_area_m2_min'] = IntegerRange.constructFromObject(data['occupied_area_m2_min']);
            }
            if (data.hasOwnProperty('walk')) {
                obj['walk'] = IntegerRange.constructFromObject(data['walk']);
            }
            if (data.hasOwnProperty('extract_number')) {
                obj['extract_number'] = SearchRange.constructFromObject(data['extract_number']);
            }
            if (data.hasOwnProperty('years_passed_from_bought_at')) {
                obj['years_passed_from_bought_at'] = ApiClient.convertToType(data['years_passed_from_bought_at'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PropertySearchRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PropertySearchRequest</code>.
     */
    static validateJSON(data) {
        if (data['prefectures']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['prefectures'])) {
                throw new Error("Expected the field `prefectures` to be an array in the JSON data but got " + data['prefectures']);
            }
            // validate the optional field `prefectures` (array)
            for (const item of data['prefectures']) {
                PropertySearchRequestPrefecturesInner.validateJSON(item);
            };
        }
        // validate the optional field `built_at`
        if (data['built_at']) { // data not null
          DateRange.validateJSON(data['built_at']);
        }
        // validate the optional field `monthly_rent_income`
        if (data['monthly_rent_income']) { // data not null
          IntegerRange.validateJSON(data['monthly_rent_income']);
        }
        // ensure the json data is a string
        if (data['management_type'] && !(typeof data['management_type'] === 'string' || data['management_type'] instanceof String)) {
            throw new Error("Expected the field `management_type` to be a primitive type in the JSON string but got " + data['management_type']);
        }
        // validate the optional field `occupied_area_m2_min`
        if (data['occupied_area_m2_min']) { // data not null
          IntegerRange.validateJSON(data['occupied_area_m2_min']);
        }
        // validate the optional field `walk`
        if (data['walk']) { // data not null
          IntegerRange.validateJSON(data['walk']);
        }
        // validate the optional field `extract_number`
        if (data['extract_number']) { // data not null
          SearchRange.validateJSON(data['extract_number']);
        }

        return true;
    }


}



/**
 * @member {Array.<module:model/PropertySearchRequestPrefecturesInner>} prefectures
 */
PropertySearchRequest.prototype['prefectures'] = undefined;

/**
 * @member {module:model/DateRange} built_at
 */
PropertySearchRequest.prototype['built_at'] = undefined;

/**
 * @member {module:model/IntegerRange} monthly_rent_income
 */
PropertySearchRequest.prototype['monthly_rent_income'] = undefined;

/**
 * 管理形態
 * @member {module:model/PropertySearchRequest.ManagementTypeEnum} management_type
 */
PropertySearchRequest.prototype['management_type'] = undefined;

/**
 * @member {module:model/IntegerRange} occupied_area_m2_min
 */
PropertySearchRequest.prototype['occupied_area_m2_min'] = undefined;

/**
 * @member {module:model/IntegerRange} walk
 */
PropertySearchRequest.prototype['walk'] = undefined;

/**
 * @member {module:model/SearchRange} extract_number
 */
PropertySearchRequest.prototype['extract_number'] = undefined;

/**
 * 購入年月日からの経過年数
 * @member {Number} years_passed_from_bought_at
 */
PropertySearchRequest.prototype['years_passed_from_bought_at'] = undefined;





/**
 * Allowed values for the <code>management_type</code> property.
 * @enum {String}
 * @readonly
 */
PropertySearchRequest['ManagementTypeEnum'] = {

    /**
     * value: "NotSelected"
     * @const
     */
    "NotSelected": "NotSelected",

    /**
     * value: "Subleasing"
     * @const
     */
    "Subleasing": "Subleasing",

    /**
     * value: "Outsourcing"
     * @const
     */
    "Outsourcing": "Outsourcing",

    /**
     * value: "MoneyCollectionAgency"
     * @const
     */
    "MoneyCollectionAgency": "MoneyCollectionAgency",

    /**
     * value: "SelfManagement"
     * @const
     */
    "SelfManagement": "SelfManagement"
};



export default PropertySearchRequest;

