import type { PropsWithChildren } from "react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Agent, Company, PropallyAgentV1Api, AgentProfileImage } from "../../api/generated/src";
import { AgentState, CompanyState, LoggedInState } from "../../view_models/atoms";

const fetchAgent = async (): Promise<Agent> => {
  return new Promise((resolve, reject) => {
    new PropallyAgentV1Api().agentV1AgentGet((error: object, data: Agent, response: object) => {
      if (error) {
        reject(response);
      } else {
        resolve(data);
      }
    });
  });
};
const fetchCompany = async (): Promise<Company> => {
  return new Promise((resolve, reject) => {
    new PropallyAgentV1Api().agentV1CompanyGet((error: object, data: Company, response: object) => {
      if (error) {
        reject(response);
      } else {
        resolve(data);
      }
    });
  });
};

export const AuthProvider: React.FC<PropsWithChildren> = (props) => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useRecoilState(LoggedInState);
  const setAgent = useSetRecoilState(AgentState);
  const setCompany = useSetRecoilState(CompanyState);
  const location = useLocation();

  useEffect(() => {
    const unauthorized = !["/sign-in", "/sign-up", "/reset-password", "/new-password"].includes(location.pathname);

    if (unauthorized) {
      // NOTE: adminからのログインの場合は、agentが存在しないのでここで毎回取得する
      // いい案が思い浮かぶまでこの状態にする
      fetchAgent()
        .then((data) => {
          data.agent_profile_image?.image_url || (data.agent_profile_image = { image_url: "/user.svg" } as AgentProfileImage);
          setAgent(data);
        })
        .catch(() => {
          setLoggedIn({ logged_in: false });
          navigate("/sign-in");
        });

      fetchCompany()
        .then((data) => {
          setCompany(data);
        })
        .catch(() => {
          setLoggedIn({ logged_in: false });
          navigate("/sign-in");
        });
    }
  }, [loggedIn]);

  return <>{props.children}</>;
};
