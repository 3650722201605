/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class JobCategory.
* @enum {}
* @readonly
*/
export default class JobCategory {
    
        /**
         * value: "会社員（上場）"
         * @const
         */
        "会社員（上場）" = "会社員（上場）";

    
        /**
         * value: "会社員（非上場）"
         * @const
         */
        "会社員（非上場）" = "会社員（非上場）";

    
        /**
         * value: "会社員（外資系企業）"
         * @const
         */
        "会社員（外資系企業）" = "会社員（外資系企業）";

    
        /**
         * value: "会社役員"
         * @const
         */
        "会社役員" = "会社役員";

    
        /**
         * value: "公務員"
         * @const
         */
        "公務員" = "公務員";

    
        /**
         * value: "士業"
         * @const
         */
        "士業" = "士業";

    
        /**
         * value: "医師・歯科医師"
         * @const
         */
        "医師・歯科医師" = "医師・歯科医師";

    
        /**
         * value: "薬剤師・看護師"
         * @const
         */
        "薬剤師・看護師" = "薬剤師・看護師";

    
        /**
         * value: "自営業"
         * @const
         */
        "自営業" = "自営業";

    
        /**
         * value: "その他"
         * @const
         */
        "その他" = "その他";

    
        /**
         * value: "入力なし"
         * @const
         */
        "入力なし" = "入力なし";

    

    /**
    * Returns a <code>JobCategory</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/JobCategory} The enum <code>JobCategory</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

