/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class SalesNoteCompleteReasonEnum.
* @enum {}
* @readonly
*/
export default class SalesNoteCompleteReasonEnum {
    
        /**
         * value: "未完了"
         * @const
         */
        "未完了" = "未完了";

    
        /**
         * value: "ローンアウト"
         * @const
         */
        "ローンアウト" = "ローンアウト";

    
        /**
         * value: "他社購入"
         * @const
         */
        "他社購入" = "他社購入";

    
        /**
         * value: "成約"
         * @const
         */
        "成約" = "成約";

    
        /**
         * value: "その他"
         * @const
         */
        "その他" = "その他";

    

    /**
    * Returns a <code>SalesNoteCompleteReasonEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/SalesNoteCompleteReasonEnum} The enum <code>SalesNoteCompleteReasonEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

