import styled from "@emotion/styled";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import {useLocation} from "react-router-dom";
import InvestmentProposalMessage from "../../components/Organisms/InvestmentProposalMessage";

const StyledAccordion = styled(Accordion)`
  &.Mui-expanded {
    margin-top: 0;
  }
`;
const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 2rem;
  background-color: #f5f5f5;
  height: 40px;
  min-height: 40px;
  font-weight: bold;
  &.Mui-expanded {
    min-height: 40px;
  }
`;

const InvestmentProposalBulkRegister: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userIdsString = queryParams.get("userIds");

  const userIds = userIdsString ? userIdsString.split(",").map((userId) => Number(userId)) : [];
  return (
    <>
      <StyledAccordion defaultExpanded={true}>
        <StyledAccordionSummary aria-controls={"proposal-input"} sx={{ height: 80, p: 0 }}>
          一括投資提案 選択中ユーザID
        </StyledAccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Box sx={{ mt: "10px", mr: "22px", mb: "25px", ml: "40px" }}>
            {userIds.map(userId => (
              <Typography
                key={userId}
                variant={"h6"}>
                ID: {userId}
              </Typography>
            ))}
          </Box>
        </AccordionDetails>
      </StyledAccordion>
      <InvestmentProposalMessage userIds={userIds} />
    </>
  );
};

export default InvestmentProposalBulkRegister;
