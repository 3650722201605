/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class MoneyAmount.
* @enum {}
* @readonly
*/
export default class MoneyAmount {
    
        /**
         * value: "未選択"
         * @const
         */
        "未選択" = "未選択";

    
        /**
         * value: "なし"
         * @const
         */
        "なし" = "なし";

    
        /**
         * value: "〜500万円"
         * @const
         */
        "〜500万円" = "〜500万円";

    
        /**
         * value: "500〜1,000万円"
         * @const
         */
        "500〜1,000万円" = "500〜1,000万円";

    
        /**
         * value: "1,000〜2,000万円"
         * @const
         */
        "1,000〜2,000万円" = "1,000〜2,000万円";

    
        /**
         * value: "2,000〜3,000万円"
         * @const
         */
        "2,000〜3,000万円" = "2,000〜3,000万円";

    
        /**
         * value: "3,000〜5,000万円"
         * @const
         */
        "3,000〜5,000万円" = "3,000〜5,000万円";

    
        /**
         * value: "5,000万円〜1億円"
         * @const
         */
        "5,000万円〜1億円" = "5,000万円〜1億円";

    
        /**
         * value: "1〜2億円"
         * @const
         */
        "1〜2億円" = "1〜2億円";

    
        /**
         * value: "2〜3億円"
         * @const
         */
        "2〜3億円" = "2〜3億円";

    
        /**
         * value: "3〜5億円"
         * @const
         */
        "3〜5億円" = "3〜5億円";

    
        /**
         * value: "5〜10億円"
         * @const
         */
        "5〜10億円" = "5〜10億円";

    
        /**
         * value: "10億円〜"
         * @const
         */
        "10億円〜" = "10億円〜";

    

    /**
    * Returns a <code>MoneyAmount</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/MoneyAmount} The enum <code>MoneyAmount</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

