import styled from "@emotion/styled";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CalculateIcon from "@mui/icons-material/Calculate";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import SearchIcon from "@mui/icons-material/Search";
import MUIButton, { type ButtonProps as MUIButtonProps } from "@mui/material/Button";
import React from "react";
import { Colors } from "../../constants/Colors";
import { Paragraph } from "../../constants/Typography";

type ButtonIconType = "calculate" | "edit" | "error" | "howToReg" | "search" | "attachFile" | "corporateFare";

type ButtonProps = {
  /**
   * The text that is displayed on the button.
   */
  label: string;

  /**
   * [Optional] An icon that is displayed on the button.
   */
  fronticon?: ButtonIconType;

  /**
   * [Optional] The design variation of the button.
   * It can be either "small", "medium", "large" or "XLarge".
   * @default "medium"
   */
  size?: MUIButtonProps["size"] | "XLarge";
  component?: React.ElementType;
} & Omit<MUIButtonProps, "size" | "component">;

const calcIconSizeBySize = (size: ButtonProps["size"]): number => {
  return size === "small" ? 17.33 : 24;
};

const calcFontSizeBySize = (size: ButtonProps["size"]): number => {
  return size === "XLarge" ? Paragraph.XL : Paragraph.M;
};

const calcPaddingBySize = (size: ButtonProps["size"]): string => {
  switch (size) {
    case "small":
      return "4px 24px";
    case "medium":
      return "8px 32px";
    case "large":
      return "12px 40px";
    case "XLarge":
      return "16px 48px";
    // default is set "M"
    default:
      return "8px 32px";
  }
};

const calcStyleByVariant = (variant: ButtonProps["variant"]) => {
  switch (variant) {
    case "outlined":
      return `
        border: 1px solid ${Colors.Border.main};
        color: ${Colors.White};

        background: linear-gradient(95.5deg, ${Colors.Button.main.left} 5.99%, ${Colors.Button.main.right} 100%);
        background-clip: text;
        text-fill-color: transparent;

        &:disabled {
          background: ${Colors.Border.main};
          background-clip: text;
          text-fill-color: transparent;
        }
      `;
    case "contained":
    default:
      return `
        background: linear-gradient(95.5deg, ${Colors.Button.main.left} 5.99%, ${Colors.Button.main.right} 100%);
        border: none;
        color: ${Colors.White};

        &:hover {
          background: ${Colors.Secondary.main};
        }

        &:disabled {
          background: ${Colors.Border.main};
        }
      `;
  }
};

const Icon = (props: {
  iconName: ButtonIconType | undefined;
  variant: ButtonProps["variant"];
  size: ButtonProps["size"];
  disabled: ButtonProps["disabled"];
}) => {
  const { iconName, variant, size, disabled } = props;
  if (!iconName) return null;

  const sizeNum = calcIconSizeBySize(size);
  const style: React.CSSProperties = {
    width: `${sizeNum}px`,
    height: `${sizeNum}px`,
    color: variant === "contained" ? Colors.White : Colors.Button.main.left,
    ...(disabled && variant === "outlined" && { color: Colors.Border.main }),
  };

  switch (iconName) {
    case "calculate":
      return <CalculateIcon style={style} />;
    case "edit":
      return <EditIcon style={style} />;
    case "error":
      return <ErrorIcon style={style} />;
    case "howToReg":
      return <HowToRegIcon style={style} />;
    case "search":
      return <SearchIcon style={style} />;
    case "attachFile":
      return <AttachFileIcon style={style} />;
    case "corporateFare":
      return <CorporateFareIcon style={style} />;
    default:
      return null;
  }
};

const Button = ({ variant = "contained", size = "medium", type = "button", ...props }: ButtonProps) => {
  const StyledButton = styled(MUIButton)`
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    font-size: ${`${calcFontSizeBySize(size)}px`};
    padding: ${calcPaddingBySize(size)};
    border-radius: 999px;
    ${calcStyleByVariant(variant)}
    backface-visibility: hidden;
    // filled の時に hover すると背景色がチラつくために none にしている
    transition: none;
  `;

  return (
    <StyledButton
      disabled={props.disabled}
      startIcon={<Icon iconName={props.fronticon} variant={variant} size={size} disabled={props.disabled} />}
      type={type}
      component={props.component || "button"}
      {...props}
    >
      <span style={{ whiteSpace: "pre-line" }}>{props.label}</span>
    </StyledButton>
  );
};
export default Button;
