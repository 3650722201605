export const Headline = {
  FontWeight: 700,
  H1: 40,
  H2: 32,
  H3: 28,
  H4: 20,
  MobileH1: 32,
  MobileH2: 24,
  MobileH3: 20,
  MobileH4: 16,
};

export const Paragraph = {
  FontWeight: 400,
  BoldFontWeight: 700,
  XL: 18,
  L: 16,
  M: 14,
  S: 12,
  XS: 10,
  MobileL: 14,
  MobileM: 12,
  MobileS: 10,
};

export const System = {
  BoldFontWeight: 700,
  FontWeight: 400,
};

export const Price = {
  FontWeight: 400,
  BoldFontWeight: 700,
  L: 16,
  M: 14,
  S: 12,
  MobileL: 14,
  MobileM: 12,
  MobileS: 10,
};
