import styled from "@emotion/styled";
import MUITextField, { type TextFieldProps as MUITextFieldProps } from "@mui/material/TextField";
import React, { ComponentPropsWithoutRef, forwardRef } from "react";

import { Colors } from "../../constants/Colors";
import { Paragraph } from "../../constants/Typography";

export type TextFieldProps = MUITextFieldProps & ComponentPropsWithoutRef<"input">;

const StyledTextField = styled(MUITextField)`
  &.MuiTextField-root {
    width: ${(props) => (props.size === "small" ? "200px" : "412px")};
  }
  & .MuiInput-underline:after {
    border-bottom-color: green;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${Colors.Border.main};
    }
    &:hover fieldset {
      border-color: ${Colors.Border.main};
    }
    &.Mui-focused fieldset {
      border-color: ${Colors.Secondary.main};
    }
  }
  & .Mui-error input {
    background: ${Colors.Error.light};
    border: 1px solid ${Colors.Error.main};
    border-radius: 4px;
  }

  & .MuiInputBase-input {
    padding: 0 14px;
    height: ${(props) => (props.size === "small" ? "36px" : "48px")};
  }
`;

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(({ ...props }, ref) => {
  return (
    <StyledTextField
      {...props}
      key={props.name}
      ref={ref}
      sx={{ fontSize: props.size === "small" ? Paragraph.M : Paragraph.L, ...props.sx }}
    />
  );
});

TextField.displayName = "TextField";
export default TextField;
