export const Colors = {
  Primary: {
    main: "#383D51",
    dark: "#25293A",
    light: "#484F67",
    outline: "#383D51",
  },
  Secondary: {
    main: "#FF6F00",
    dark: "#E65100",
    light: "#FF8F00",
    outline: "#FF6F00",
  },
  Button: {
    main: {
      left: "#FF6232",
      right: "#FF9347",
    },
    sub: "#CCCCCC",
    cvr: "#FFD600",
  },
  Link: "#2962FF",
  Text: {
    main: "#424750",
  },
  Background: "#F5F5F5",
  Border: {
    main: "#D5DAE4",
    light: "#EEEEEE",
  },
  Label: "#5C6472",
  Placeholder: "#A0A3BD",
  Error: {
    main: "#DC3645",
    light: "#FAE6E8",
  },
  Success: "#00BA88",
  Ranking: {
    first: "#B7A87D",
    second: "#9C9C9C",
    third: "#907C64",
  },
  Facebook: "#3B5998",
  Twitter: "#1DA1F2",
  Instagram: "#ED4956",
  Line: "#00C200",
  White: "#FFFFFF",
  Black: "#000000",
};
